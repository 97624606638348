import { FormModelSchema } from '../../models/dynamic-compopent/form-model'

export const FORM_PRODUCT: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'product',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Status',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 12,
                },
                // {
                //     title: 'Region',
                //     component: 'SelectOptionComponent',
                //     options: {
                //         remote: true,
                //         apiModel: {
                //             path: 'region',
                //             params: 'sort=title ASC'
                //         },
                //         value: 'name',
                //         defaultValue: 'region.name'
                //     },
                //     model: 'region',
                //     blockSize: 6
                // },
                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 6
                },
                {
                    title: 'Category',
                    component: 'SelectOptionComponent',
                    options: {
                        remote: true,
                        apiModel: {
                            path: 'category',
                            params: 'sort=title ASC'
                        },
                        value: 'title:lang',
                        defaultValue: 'category.title:lang'
                    },
                    model: 'category',
                    blockSize: 6
                },
                {
                    title: 'Description',
                    component: 'TextEditorComponent',
                    model: 'body:lang',
                    blockSize: 12
                },
                {
                    title: 'Featured',
                    component: 'ButtonFileComponent',
                    model: 'featured',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 6
                },
            ]
        }
    }
}

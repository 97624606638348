import {Injectable} from '@angular/core'
import {CardLayoutSchema} from '../../models/dynamic-compopent/card-model'
import {FORM_EDITOR} from '../../forms/editor/form-editor'
import {Subject} from 'rxjs/Subject'

@Injectable()
export class EditorComponentService {

    cardDetailSchema: CardLayoutSchema
    editorFormList = FORM_EDITOR


    private _$burgerListener: Subject<boolean> = new Subject<boolean>()


    constructor() {

    }

    getFormEditor(resolve: any) {

        let form: any

        form = this.editorFormList[resolve.manage]

        return new Promise(resolve => resolve(form))

    }


    $setBurgerListener(val) {
        this._$burgerListener.next(val)
    }


    $getBurgerListener() {

        return this._$burgerListener.asObservable()
    }


}

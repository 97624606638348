import {FormModelSchema} from '../../models/dynamic-compopent/form-model'

export const FORM_AFFILIATES_BIMASENA: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'partner',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 12,
                },

                {
                    title: 'Name',
                    component: 'InputComponent',
                    model: 'name',
                    blockSize: 12
                },

                {
                    title: 'Location',
                    component: 'InputComponent',
                    model: 'location.address',
                    blockSize: 12
                },

                {
                    title: 'Phone',
                    component: 'InputComponent',
                    model: 'contact.phone',
                    blockSize: 4
                },

                {
                    title: 'Facsimile',
                    component: 'InputComponent',
                    model: 'contact.fax',
                    blockSize: 4
                },

                {
                    title: 'Website',
                    component: 'InputComponent',
                    model: 'contact.website',
                    blockSize: 4
                },
            ]
        },
    }
}

import {NgModule} from '@angular/core'
import {AuthGuard} from './auth-guard'
import {UserRoleChecker} from './user-role-checker.service'

@NgModule({
    providers: [
        AuthGuard,
        UserRoleChecker
    ]
})
export class GuardBundleModule {

}

import {Injectable} from '@angular/core'
import {FileUploadService} from './file-upload.service'
import {CrudService} from '../crud-service'

@Injectable()
export class FileManagerService {

    fileType = ['Document', 'Image']

    folders: any = []

    constructor(private upload: FileUploadService,
                private _crudService: CrudService) {

    }

    async getFolder() {

        let filesData: any = []

        const files: any = await Promise.all([
            this._crudService.GET('document', {
                    params: {
                        limit: 999,
                        sort: 'created_at DESC'
                    }
                }
            ),
            this._crudService.GET('picture', {
                    params: {
                        limit: 999,
                        sort: 'created_at DESC'
                    }
                }
            )
        ])

        files.map((file, index) => {
            filesData[index] = {
                folder_name: this.fileType[index],
                files: [],
                total: file.total
            }

            filesData[index].files.push(...file.results)
        })


        console.log('filesData', filesData)


        /**
         * Create folder
         */
        // LOOPING(files, (data, index) => {
        //
        //     filesData[index] = {
        //         id: $UID(),
        //         title: this.fileType[index],
        //         folders: {},
        //         files: []
        //     }
        //
        //     data.forEach((f, i) => {
        //
        //         if (f.type) {
        //
        //             filesData[index].folders[f.type] = {
        //                 title: f.type,
        //                 id: $UID(),
        //                 files: []
        //             }
        //         } else {
        //             filesData[index].files.push(f)
        //         }
        //     })
        // })


        /**
         * Insert file into folder
         */
        // LOOPING(files, (data, index) => {
        //
        //     const folders: any = filesData[index].folders
        //
        //     LOOPING(data, (f) => {
        //
        //         for (let folder in folders) {
        //             if (f.type === folder)
        //                 folders[f.type].files.push(f)
        //         }
        //
        //     })
        //
        // })

        this.folders = filesData

        return filesData

    }

    getImages(folder) {
        return this._crudService.GET('picture', {
            params: {
                type: folder,
                limit: 20
            }
        })
    }

}

import {Injectable} from '@angular/core'
import {Subject} from 'rxjs/Subject'
import {Observable} from 'rxjs/Observable'

@Injectable()
export class CardExpandService {

    cardData: any
    isCardExpanding: boolean
    private $listenEventFromCard: Subject<any> = new Subject<any>()
    private $listenEventClose: Subject<any> = new Subject<any>()

    constructor() {

    }


    $setCardData(value: any) {
        this.cardData = value
        this.$listenEventFromCard.next(value)
    }

    $getCardData(): Observable<any> {
        return this.$listenEventFromCard.asObservable()
    }


    $expandCardIsClose() {
        this.$listenEventClose.next(true)
    }


    $getXpandCardIsClose(): Observable<any> {
        return this.$listenEventClose.asObservable()
    }

}


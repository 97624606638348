import {AfterViewInit, Component, OnInit} from '@angular/core'
import {NotifDialogService} from '../../services/notif-service'
import {NotifDialogModel} from '../../models/notif/notif-dialog-model'
import {TimeoutWait} from '../../helpers/helper-async-timeout'

@Component({
    selector: 'vold-notif-dialog',
    templateUrl: './notif-dialog.component.html',
    styleUrls: ['./notif-dialog.component.scss']
})
export class NotifDialogComponent implements OnInit, AfterViewInit {

    options: NotifDialogModel = new NotifDialogModel()
    showDialog: boolean

    constructor(private _notifService: NotifDialogService) {
    }

    ngOnInit() {
    }

    async ngAfterViewInit() {

        this._notifService.$watchDialog().subscribe(async (option: NotifDialogModel) => {
            this.options = option
            await TimeoutWait(200)
            this.showDialog = true

            /**
             * Gusna taen mai
             */
            if (option.timeOutClose && option.type !== 'confirm' ) {
                await TimeoutWait(option.timeOutClose)
                await this.close()
            }
        })

    }

    async close() {
        this.showDialog = false
        await TimeoutWait(400)
        this.options = new NotifDialogModel()
    }

    async confirm(action?: boolean) {
        this.options.confirmFn(action)
        this.close()
    }

}

import {EventEmitter, Injectable} from '@angular/core'
import {Subject} from 'rxjs/Subject'
import {CrudService} from '../crud-service'
import {Observable} from 'rxjs/Rx'
import {TimeoutWait} from '../../helpers/helper-async-timeout'

@Injectable()
export class EditorDataService {

    singleData: any = null
    cardDetail: any = null
    $relatedDataUpdated: Subject<any> = new Subject<any>()

    private _$listenUpdate: Subject<any> = new Subject<any>()

    $onSaveData: EventEmitter<any> = new EventEmitter<any>(true)

    private _$listenImageInsterted: Subject<any> = new Subject<any>()

    private _$watchDataBeingPrepare: Subject<any> = new Subject<any>()

    constructor(private _crudService: CrudService) {

    }


    $setImageInserted() {
        this._$listenImageInsterted.next(true)
    }

    $getImageInserted() {
        return this._$listenImageInsterted.asObservable()
    }


    $setRelatedData(value) {
        this.$relatedDataUpdated.next(value)
    }

    $getRelatedData() {
        return this.$relatedDataUpdated.asObservable()
    }


    $setSingleDetail(data: any) {

        if (Array.isArray(data)) this.singleData = data[0]
        else this.singleData = data
        this._$listenUpdate.next(data)

    }

    $getSingleDetail() {
        return this._$listenUpdate.asObservable()
    }


    $setDataBeingPrepare(val) {
        this._$watchDataBeingPrepare.next(val)
    }


    $getPreparedData(): Observable<any> {
        return this._$watchDataBeingPrepare.asObservable()
    }


    async update(model: string, data?: any) {

        await TimeoutWait(200)
        let id = this.singleData.id
        let dataSent = this.singleData

        if (data) {
            id = data.id
            dataSent = data
        }

        const dataUpdated = await this._crudService.PUT(`${model}`, id, dataSent)

        /**
         *
         * Update model to listen from controller
         */
        this.$setSingleDetail(dataUpdated)

        return Array.isArray(dataUpdated) ? dataUpdated[0] : dataUpdated
    }
}

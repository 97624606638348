import {FormModelSchema} from '../../../../models/dynamic-compopent/form-model'

export const FORM_CLIENT_JCO_PERMISSION: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'g/control/diff',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Roles Registered',
                    component: 'CheckBoxComponentComponent',
                    model: 'allow',
                    checkBox: {
                        label: 'title',
                        setValueModal: 'id',
                        checkAll: true,
                        callBackOnClick: async (item, index, self: any) => {
                            await self._crudService.POST('g/control/remove', {
                                role_id: item.id,
                                acp_id: self.model.control.id
                            })
                            self.selectedOptions.splice(index, 1)
                        }
                    },
                },

                {
                    title: 'Unregistered Roles',
                    component: 'CheckBoxComponentComponent',
                    model: 'not_allowed',
                    checkBox: {
                        label: 'title',
                        setValueModal: 'id',
                        callBackOnClick: async (item, index, self: any) => {
                            await self._crudService.POST('g/control/add', {
                                role_id: item.id,
                                acp_id: self.model.control.id
                            })
                            self.selectedOptions.splice(index, 1)
                        }
                    },
                }
            ]
        }
    }
}

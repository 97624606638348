import {Injectable} from '@angular/core'
import {Subject} from 'rxjs/Subject'

@Injectable()
export class ElasticDataService {

    private _$listenElastic: Subject<any> = new Subject<any>()

    /**
     * ELASTIC
     */
    $setElasticData(val) {
        this._$listenElastic.next(val)
    }

    $getElasticData() {
        return this._$listenElastic.asObservable()
    }
}

import { FormModelSchema } from '../../models/dynamic-compopent/form-model'

export const FORM_EVENT_BIMASENA: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'event',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 12,
                },

                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 12
                },

                {
                    title: 'Summary',
                    component: 'InputComponent',
                    model: 'summary:lang',
                    blockSize: 12
                },

                {
                    title: 'Event Date',
                    component: 'InputCalendarComponent',
                    inputCalendar: {
                        blockSize: 9,
                        startDate: true,
                        dateFormat: 'dd/MM/yyyy'
                    },
                    model: 'datetime,%d',
                    blockSize: 6
                },

                {
                    title: 'Place',
                    component: 'InputComponent',
                    model: 'place',
                    blockSize: 6
                },

                {
                    title: 'Event Type',
                    component: 'SelectOptionComponent',
                    options: {
                        remote: true,
                        apiModel: {
                            path: 'category',
                            params : 'sort=title ASC'
                        },
                        value: 'title:lang',
                        defaultValue: 'category.title:lang',
                    },
                    model: 'category',
                    blockSize: 6
                },

                {
                    title: 'Body',
                    component: 'TextEditorComponent',
                    model: 'body:lang',
                    blockSize: 12
                },
            ]
        },

        projectCost: {
            fields: [
                {
                    title: 'Featured',
                    component: 'ButtonFileComponent',
                    model: 'featured',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 6
                },
                {
                    title: 'Og Image',
                    component: 'ButtonFileComponent',
                    model: 'og_image',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 6
                }
            ]
        },

        gallery: {
            fields: [
                {
                    title: 'Gallery',
                    component: 'ButtonFileComponent',
                    model: 'gallery',
                    blockSize: 12,
                    buttonFile: {
                        icon: 'icon-plus',
                        buttonType: 'image',
                        folderTarget: 'post',
                        multiple: true
                    }
                },
            ]
        },
    }
}

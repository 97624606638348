import {Injectable} from '@angular/core'
import {HttpClient, HttpParams} from '@angular/common/http'
import {environment} from '../../environments/environment'
import {StorageService} from './storage-service'

@Injectable()
export class CrudService {

    constructor(private _http: HttpClient,
                private _storage: StorageService) {
    }


    GET(model: string, options: any = {}) {
        return this._http.get(`${environment.api_uri}${this._storage.get('client_id')}/${model}`, options).toPromise()

    }


    FULL_PATH_GET(path: any, options: any = {}) {
        return this._http.get(`${path}`, options)
    }


    SINGLE(model: string, id: string) {
        return this.GET(`${model}/${id}`)
    }

    EXPORT_CSV(model, options: any = {}) {
        return this._http.get(`${environment.api_uri}${this._storage.get('client_id')}/g/${model}/exporttocsv`, options)
    }


    POST(model: string, form: any = {}) {

        let url = `${environment.api_uri}${this._storage.get('client_id')}/`

        if (!this._storage.get('client_id')) url = `${environment.api_uri}`


        return this._http.post(`${url}${model}`, form)
            .toPromise()
    }


    POST_FULL(url: string, form: any = {}) {

        return this._http.post(`${url}`, form)
            .toPromise()
    }

    DELETE(model: string, id: string) {
        return this._http.delete(`${environment.api_uri}${this._storage.get('client_id')}/${model}/${id}`)
            .toPromise()
    }

    PUT(model: string, id: string, body: any = {}) {
        return this._http.put(`${environment.api_uri}${this._storage.get('client_id')}/${model}/${id}`, body)
            .toPromise()
    }


    ELASTIC(model?: string, body: any = {}) {
        return this.POST(`elastic/search${model}`, body)
    }

    ELASTIC_AUTO_COMPLETE(params: any = {}, api: string = 'restricted_prefixsuggester') {
        return this.GET(`elastic/${api}`, {params: this.toHttpParams(params)})
    }


    toHttpParams(params) {

        let map = new HttpParams()

        if (typeof params !== 'object') {
            map = params
        } else {
            Object.keys(params).forEach((key) => {
                map = map.append(key, params[key])
            })
        }

        return map
    }

}

export class DateDateModel {
    dateStamp: Date
    today: boolean
    day: number
    date: number
    year: number
    dateOfCurrentMonth: boolean
    monthName: string
    monthIndex: number
}

export class CalendarCore {

    date = new Date()

    months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

    /**
     * Set current selected month and year
     */
    currentSelectedMonth: number
    currentSelectedYear: number

    currentSelectedMonthIndex: number


    constructor() {

    }

    nextMonth() {

        this.date.setMonth(this.date.getMonth() + 1)
        this.currentSelectedMonth = this.date.getMonth()
        this.currentSelectedYear = this.date.getFullYear()

    }


    prevMonth() {
        this.date.setMonth(this.date.getMonth() - 1)
        this.currentSelectedMonth = this.date.getMonth()
        this.currentSelectedYear = this.date.getFullYear()
    }

}

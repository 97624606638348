import {Injectable} from '@angular/core'
import {Subject} from 'rxjs/Subject'

@Injectable()
export class DragDropService {

    private _$activateSortItem: Subject<boolean> = new Subject<boolean>()


    $activateSortItem(val) {
        this._$activateSortItem.next(val)
    }


    $getActivatedSort() {
        return this._$activateSortItem.asObservable()
    }

}

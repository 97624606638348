import {Injectable} from '@angular/core'
import {EditorDataService} from './editor-data-service'
import {CrudService} from '../crud-service'
import {EditorComponentService} from './editor-component-service'
import {Subject} from 'rxjs/Rx'

@Injectable()
export class EditorService {

    watcher: any = []
    currentEditedPage: string

    constructor(public data: EditorDataService,
                public component: EditorComponentService,
                private _crudService: CrudService) {
    }

    async getSingle(resolve: any, keyState?: any) {

        let data = this.data.singleData
        if (!this.data.singleData && !keyState) {
            this.data.singleData = data = await this._crudService.GET(`${resolve.model}/${resolve.id}`)
        }

        if (!this.data.singleData && keyState) {
            this.data.singleData = data = await this._crudService.GET(`${resolve.model}?${keyState}=${resolve.id}`)
        }

        this.data.$setSingleDetail(data)

        return data

    }


}

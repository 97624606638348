import {FormModelSchema} from '../../../models/dynamic-compopent/form-model'

export const FORM_CLIENT_SERVICE: FormModelSchema = {
    baseRoute: 'vip',
    pathModel: 'service',
    group: {
        'projectDetail': {
            fields: [
                // {
                //     title: 'Status',
                //     component: 'SlideToggleComponent',
                //     slideToggle: {
                //         values: ['published', 'draft']
                //     },
                //     model: 'status',
                //     blockSize: 6,
                // },

                {
                    title: 'Is active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: [true, false]
                    },
                    model: 'is_active',
                    blockSize: 6,
                },

                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'name',
                    blockSize: 12
                },

                {
                    title: 'Price',
                    component: 'InputComponent',
                    model: 'price',
                    type: 'number',
                    blockSize: 12
                },

            ]
        }
    }
}

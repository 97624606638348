import {FormModelSchema} from '../../models/dynamic-compopent/form-model'

export const FORM_LEARN: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'news',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 6,
                },

                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 12
                },
                {
                    title: 'Summary',
                    component: 'TextareaComponent',
                    model: 'body:lang',
                    blockSize: 12
                },

                // {
                //     title: 'Body',
                //     component: 'TextEditorComponent',
                //     model: 'body:lang',
                //     blockSize: 12
                // },
            ]
        },
        steps: {
            fields: [
                {
                    component: 'MultipleAddComponent',
                    model: 'meta.steps',
                    title: 'Steps',
                    blockSize: 12,
                    multipleAdd: {
                        blockSize: 6,
                        buttonTitle: 'title:lang'
                    },
                    multipleComponentToUse: [
                        {
                            title: 'Title',
                            component: 'InputComponent',
                            model: 'title:lang',
                            blockSize: 12,
                        },
                        {
                            title: 'Body',
                            component: 'TextareaComponent',
                            model: 'description:lang',
                            blockSize: 12
                        },
                        {
                            title: 'Image',
                            component: 'ButtonFileComponent',
                            model: 'image',
                            buttonFile: {
                                buttonType: 'image',
                                folderTarget: 'post',
                            },
                            blockSize: 6
                        },
                    ]
                }
            ]
        },

        projectCost: {
            title: 'Featured Image',
            fields: [
                {
                    title: 'Featured',
                    component: 'ButtonFileComponent',
                    model: 'featured',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 6
                },
            ]
        },
    }
}

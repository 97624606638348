import {FormModelSchema} from '../../../../models/dynamic-compopent/form-model'

export const FORM_CLIENT_JCO_REGION: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'region',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 12,
                },
                {
                    title: 'Region Name',
                    component: 'InputComponent',
                    model: 'name',
                    blockSize: 6
                },
                {
                    title: 'Region Code',
                    component: 'InputComponent',
                    model: 'code',
                    blockSize: 6
                },
                {
                    title: 'Online Order Availability',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: [true, false]
                    },
                    model: 'config.is_online_order',
                    blockSize: 12,
                },
                {
                    title: 'Online Order URL',
                    component: 'InputComponent',
                    model: 'config.online_order_link',
                    blockSize: 6
                }
                

                // {
                //     title: 'Language',
                //     component: 'CheckBoxComponentComponent',
                //     model: 'language',
                //     checkBox: {
                //         label: 'title',
                //         setValueModal: 'code',
                //         options: [
                //             {
                //                 code: 'ab',
                //                 'title': 'Abkhaz',
                //                 'nativetitle': 'аҧсуа'
                //             },
                //             {
                //                 code: 'aa',
                //                 'title': 'Afar',
                //                 'nativetitle': 'Afaraf'
                //             },
                //             {
                //                 code: 'af',
                //                 'title': 'Afrikaans',
                //                 'nativetitle': 'Afrikaans'
                //             },
                //             {
                //                 code: 'ak',
                //                 'title': 'Akan',
                //                 'nativetitle': 'Akan'
                //             },
                //             {
                //                 code: 'sq',
                //                 'title': 'Albanian',
                //                 'nativetitle': 'Shqip'
                //             },
                //             {
                //                 code: 'am',
                //                 'title': 'Amharic',
                //                 'nativetitle': 'አማርኛ'
                //             },
                //             {
                //                 code: 'ar',
                //                 'title': 'Arabic',
                //                 'nativetitle': 'العربية'
                //             },
                //             {
                //                 code: 'an',
                //                 'title': 'Aragonese',
                //                 'nativetitle': 'Aragonés'
                //             },
                //             {
                //                 code: 'hy',
                //                 'title': 'Armenian',
                //                 'nativetitle': 'Հայերեն'
                //             },
                //             {
                //                 code: 'as',
                //                 'title': 'Assamese',
                //                 'nativetitle': 'অসমীয়া'
                //             },
                //             {
                //                 code: 'av',
                //                 'title': 'Avaric',
                //                 'nativetitle': 'авар мацӀ, магӀарул мацӀ'
                //             },
                //             {
                //                 code: 'ae',
                //                 'title': 'Avestan',
                //                 'nativetitle': 'avesta'
                //             },
                //             {
                //                 code: 'ay',
                //                 'title': 'Aymara',
                //                 'nativetitle': 'aymar aru'
                //             },
                //             {
                //                 code: 'az',
                //                 'title': 'Azerbaijani',
                //                 'nativetitle': 'azərbaycan dili'
                //             },
                //             {
                //                 code: 'bm',
                //                 'title': 'Bambara',
                //                 'nativetitle': 'bamanankan'
                //             },
                //             {
                //                 code: 'ba',
                //                 'title': 'Bashkir',
                //                 'nativetitle': 'башҡорт теле'
                //             },
                //             {
                //                 code: 'eu',
                //                 'title': 'Basque',
                //                 'nativetitle': 'euskara, euskera'
                //             },
                //             {
                //                 code: 'be',
                //                 'title': 'Belarusian',
                //                 'nativetitle': 'Беларуская'
                //             },
                //             {
                //                 code: 'bn',
                //                 'title': 'Bengali',
                //                 'nativetitle': 'বাংলা'
                //             },
                //             {
                //                 code: 'bh',
                //                 'title': 'Bihari',
                //                 'nativetitle': 'भोजपुरी'
                //             },
                //             {
                //                 code: 'bi',
                //                 'title': 'Bislama',
                //                 'nativetitle': 'Bislama'
                //             },
                //             {
                //                 code: 'bs',
                //                 'title': 'Bosnian',
                //                 'nativetitle': 'bosanski jezik'
                //             },
                //             {
                //                 code: 'br',
                //                 'title': 'Breton',
                //                 'nativetitle': 'brezhoneg'
                //             },
                //             {
                //                 code: 'bg',
                //                 'title': 'Bulgarian',
                //                 'nativetitle': 'български език'
                //             },
                //             {
                //                 code: 'my',
                //                 'title': 'Burmese',
                //                 'nativetitle': 'ဗမာစာ'
                //             },
                //             {
                //                 code: 'ca',
                //                 'title': 'Catalan; Valencian',
                //                 'nativetitle': 'Català'
                //             },
                //             {
                //                 code: 'ch',
                //                 'title': 'Chamorro',
                //                 'nativetitle': 'Chamoru'
                //             },
                //             {
                //                 code: 'ce',
                //                 'title': 'Chechen',
                //                 'nativetitle': 'нохчийн мотт'
                //             },
                //             {
                //                 code: 'ny',
                //                 'title': 'Chichewa; Chewa; Nyanja',
                //                 'nativetitle': 'chiCheŵa, chinyanja'
                //             },
                //             {
                //                 code: 'zh',
                //                 'title': 'Chinese',
                //                 'nativetitle': '中文 (Zhōngwén), 汉语, 漢語'
                //             },
                //             {
                //                 code: 'cv',
                //                 'title': 'Chuvash',
                //                 'nativetitle': 'чӑваш чӗлхи'
                //             },
                //             {
                //                 code: 'kw',
                //                 'title': 'Cornish',
                //                 'nativetitle': 'Kernewek'
                //             },
                //             {
                //                 code: 'co',
                //                 'title': 'Corsican',
                //                 'nativetitle': 'corsu, lingua corsa'
                //             },
                //             {
                //                 code: 'cr',
                //                 'title': 'Cree',
                //                 'nativetitle': 'ᓀᐦᐃᔭᐍᐏᐣ'
                //             },
                //             {
                //                 code: 'hr',
                //                 'title': 'Croatian',
                //                 'nativetitle': 'hrvatski'
                //             },
                //             {
                //                 code: 'cs',
                //                 'title': 'Czech',
                //                 'nativetitle': 'česky, čeština'
                //             },
                //             {
                //                 code: 'da',
                //                 'title': 'Danish',
                //                 'nativetitle': 'dansk'
                //             },
                //             {
                //                 code: 'dv',
                //                 'title': 'Divehi; Dhivehi; Maldivian;',
                //                 'nativetitle': 'ދިވެހި'
                //             },
                //             {
                //                 code: 'nl',
                //                 'title': 'Dutch',
                //                 'nativetitle': 'Nederlands, Vlaams'
                //             },
                //             {
                //                 code: 'en',
                //                 'title': 'English',
                //                 'nativetitle': 'English'
                //             },
                //             {
                //                 code: 'eo',
                //                 'title': 'Esperanto',
                //                 'nativetitle': 'Esperanto'
                //             },
                //             {
                //                 code: 'et',
                //                 'title': 'Estonian',
                //                 'nativetitle': 'eesti, eesti keel'
                //             },
                //             {
                //                 code: 'ee',
                //                 'title': 'Ewe',
                //                 'nativetitle': 'Eʋegbe'
                //             },
                //             {
                //                 code: 'fo',
                //                 'title': 'Faroese',
                //                 'nativetitle': 'føroyskt'
                //             },
                //             {
                //                 code: 'fj',
                //                 'title': 'Fijian',
                //                 'nativetitle': 'vosa Vakaviti'
                //             },
                //             {
                //                 code: 'fi',
                //                 'title': 'Finnish',
                //                 'nativetitle': 'suomi, suomen kieli'
                //             },
                //             {
                //                 code: 'fr',
                //                 'title': 'French',
                //                 'nativetitle': 'français, langue française'
                //             },
                //             {
                //                 code: 'ff',
                //                 'title': 'Fula; Fulah; Pulaar; Pular',
                //                 'nativetitle': 'Fulfulde, Pulaar, Pular'
                //             },
                //             {
                //                 code: 'gl',
                //                 'title': 'Galician',
                //                 'nativetitle': 'Galego'
                //             },
                //             {
                //                 code: 'ka',
                //                 'title': 'Georgian',
                //                 'nativetitle': 'ქართული'
                //             },
                //             {
                //                 code: 'de',
                //                 'title': 'German',
                //                 'nativetitle': 'Deutsch'
                //             },
                //             {
                //                 code: 'el',
                //                 'title': 'Greek, Modern',
                //                 'nativetitle': 'Ελληνικά'
                //             },
                //             {
                //                 code: 'gn',
                //                 'title': 'Guaraní',
                //                 'nativetitle': 'Avañeẽ'
                //             },
                //             {
                //                 code: 'gu',
                //                 'title': 'Gujarati',
                //                 'nativetitle': 'ગુજરાતી'
                //             },
                //             {
                //                 code: 'ht',
                //                 'title': 'Haitian; Haitian Creole',
                //                 'nativetitle': 'Kreyòl ayisyen'
                //             },
                //             {
                //                 code: 'ha',
                //                 'title': 'Hausa',
                //                 'nativetitle': 'Hausa, هَوُسَ'
                //             },
                //             {
                //                 code: 'he',
                //                 'title': 'Hebrew (modern)',
                //                 'nativetitle': 'עברית'
                //             },
                //             {
                //                 code: 'hz',
                //                 'title': 'Herero',
                //                 'nativetitle': 'Otjiherero'
                //             },
                //             {
                //                 code: 'hi',
                //                 'title': 'Hindi',
                //                 'nativetitle': 'हिन्दी, हिंदी'
                //             },
                //             {
                //                 code: 'ho',
                //                 'title': 'Hiri Motu',
                //                 'nativetitle': 'Hiri Motu'
                //             },
                //             {
                //                 code: 'hu',
                //                 'title': 'Hungarian',
                //                 'nativetitle': 'Magyar'
                //             },
                //             {
                //                 code: 'ia',
                //                 'title': 'Interlingua',
                //                 'nativetitle': 'Interlingua'
                //             },
                //             {
                //                 code: 'id',
                //                 'title': 'Indonesian',
                //                 'nativetitle': 'Bahasa Indonesia'
                //             },
                //             {
                //                 code: 'ie',
                //                 'title': 'Interlingue',
                //                 'nativetitle': 'Originally called Occidental; then Interlingue after WWII'
                //             },
                //             {
                //                 code: 'ga',
                //                 'title': 'Irish',
                //                 'nativetitle': 'Gaeilge'
                //             },
                //             {
                //                 code: 'ig',
                //                 'title': 'Igbo',
                //                 'nativetitle': 'Asụsụ Igbo'
                //             },
                //             {
                //                 code: 'ik',
                //                 'title': 'Inupiaq',
                //                 'nativetitle': 'Iñupiaq, Iñupiatun'
                //             },
                //             {
                //                 code: 'io',
                //                 'title': 'Ido',
                //                 'nativetitle': 'Ido'
                //             },
                //             {
                //                 code: 'is',
                //                 'title': 'Icelandic',
                //                 'nativetitle': 'Íslenska'
                //             },
                //             {
                //                 code: 'it',
                //                 'title': 'Italian',
                //                 'nativetitle': 'Italiano'
                //             },
                //             {
                //                 code: 'iu',
                //                 'title': 'Inuktitut',
                //                 'nativetitle': 'ᐃᓄᒃᑎᑐᑦ'
                //             },
                //             {
                //                 code: 'ja',
                //                 'title': 'Japanese',
                //                 'nativetitle': '日本語 (にほんご／にっぽんご)'
                //             },
                //             {
                //                 code: 'jv',
                //                 'title': 'Javanese',
                //                 'nativetitle': 'basa Jawa'
                //             },
                //             {
                //                 code: 'kl',
                //                 'title': 'Kalaallisut, Greenlandic',
                //                 'nativetitle': 'kalaallisut, kalaallit oqaasii'
                //             },
                //             {
                //                 code: 'kn',
                //                 'title': 'Kannada',
                //                 'nativetitle': 'ಕನ್ನಡ'
                //             },
                //             {
                //                 code: 'kr',
                //                 'title': 'Kanuri',
                //                 'nativetitle': 'Kanuri'
                //             },
                //             {
                //                 code: 'ks',
                //                 'title': 'Kashmiri',
                //                 'nativetitle': 'कश्मीरी, كشميري‎'
                //             },
                //             {
                //                 code: 'kk',
                //                 'title': 'Kazakh',
                //                 'nativetitle': 'Қазақ тілі'
                //             },
                //             {
                //                 code: 'km',
                //                 'title': 'Khmer',
                //                 'nativetitle': 'ភាសាខ្មែរ'
                //             },
                //             {
                //                 code: 'ki',
                //                 'title': 'Kikuyu, Gikuyu',
                //                 'nativetitle': 'Gĩkũyũ'
                //             },
                //             {
                //                 code: 'rw',
                //                 'title': 'Kinyarwanda',
                //                 'nativetitle': 'Ikinyarwanda'
                //             },
                //             {
                //                 code: 'ky',
                //                 'title': 'Kirghiz, Kyrgyz',
                //                 'nativetitle': 'кыргыз тили'
                //             },
                //             {
                //                 code: 'kv',
                //                 'title': 'Komi',
                //                 'nativetitle': 'коми кыв'
                //             },
                //             {
                //                 code: 'kg',
                //                 'title': 'Kongo',
                //                 'nativetitle': 'KiKongo'
                //             },
                //             {
                //                 code: 'ko',
                //                 'title': 'Korean',
                //                 'nativetitle': '한국어 (韓國語), 조선말 (朝鮮語)'
                //             },
                //             {
                //                 code: 'ku',
                //                 'title': 'Kurdish',
                //                 'nativetitle': 'Kurdî, كوردی‎'
                //             },
                //             {
                //                 code: 'kj',
                //                 'title': 'Kwanyama, Kuanyama',
                //                 'nativetitle': 'Kuanyama'
                //             },
                //             {
                //                 code: 'la',
                //                 'title': 'Latin',
                //                 'nativetitle': 'latine, lingua latina'
                //             },
                //             {
                //                 code: 'lb',
                //                 'title': 'Luxembourgish, Letzeburgesch',
                //                 'nativetitle': 'Lëtzebuergesch'
                //             },
                //             {
                //                 code: 'lg',
                //                 'title': 'Luganda',
                //                 'nativetitle': 'Luganda'
                //             },
                //             {
                //                 code: 'li',
                //                 'title': 'Limburgish, Limburgan, Limburger',
                //                 'nativetitle': 'Limburgs'
                //             },
                //             {
                //                 code: 'ln',
                //                 'title': 'Lingala',
                //                 'nativetitle': 'Lingála'
                //             },
                //             {
                //                 code: 'lo',
                //                 'title': 'Lao',
                //                 'nativetitle': 'ພາສາລາວ'
                //             },
                //             {
                //                 code: 'lt',
                //                 'title': 'Lithuanian',
                //                 'nativetitle': 'lietuvių kalba'
                //             },
                //             {
                //                 code: 'lu',
                //                 'title': 'Luba-Katanga',
                //                 'nativetitle': ''
                //             },
                //             {
                //                 code: 'lv',
                //                 'title': 'Latvian',
                //                 'nativetitle': 'latviešu valoda'
                //             },
                //             {
                //                 code: 'gv',
                //                 'title': 'Manx',
                //                 'nativetitle': 'Gaelg, Gailck'
                //             },
                //             {
                //                 code: 'mk',
                //                 'title': 'Macedonian',
                //                 'nativetitle': 'македонски јазик'
                //             },
                //             {
                //                 code: 'mg',
                //                 'title': 'Malagasy',
                //                 'nativetitle': 'Malagasy fiteny'
                //             },
                //             {
                //                 code: 'ms',
                //                 'title': 'Malay',
                //                 'nativetitle': 'bahasa Melayu, بهاس ملايو‎'
                //             },
                //             {
                //                 code: 'ml',
                //                 'title': 'Malayalam',
                //                 'nativetitle': 'മലയാളം'
                //             },
                //             {
                //                 code: 'mt',
                //                 'title': 'Maltese',
                //                 'nativetitle': 'Malti'
                //             },
                //             {
                //                 code: 'mi',
                //                 'title': 'Māori',
                //                 'nativetitle': 'te reo Māori'
                //             },
                //             {
                //                 code: 'mr',
                //                 'title': 'Marathi (Marāṭhī)',
                //                 'nativetitle': 'मराठी'
                //             },
                //             {
                //                 code: 'mh',
                //                 'title': 'Marshallese',
                //                 'nativetitle': 'Kajin M̧ajeļ'
                //             },
                //             {
                //                 code: 'mn',
                //                 'title': 'Mongolian',
                //                 'nativetitle': 'монгол'
                //             },
                //             {
                //                 code: 'na',
                //                 'title': 'Nauru',
                //                 'nativetitle': 'Ekakairũ Naoero'
                //             },
                //             {
                //                 code: 'nv',
                //                 'title': 'Navajo, Navaho',
                //                 'nativetitle': 'Diné bizaad, Dinékʼehǰí'
                //             },
                //             {
                //                 code: 'nb',
                //                 'title': 'Norwegian Bokmål',
                //                 'nativetitle': 'Norsk bokmål'
                //             },
                //             {
                //                 code: 'nd',
                //                 'title': 'North Ndebele',
                //                 'nativetitle': 'isiNdebele'
                //             },
                //             {
                //                 code: 'ne',
                //                 'title': 'Nepali',
                //                 'nativetitle': 'नेपाली'
                //             },
                //             {
                //                 code: 'ng',
                //                 'title': 'Ndonga',
                //                 'nativetitle': 'Owambo'
                //             },
                //             {
                //                 code: 'nn',
                //                 'title': 'Norwegian Nynorsk',
                //                 'nativetitle': 'Norsk nynorsk'
                //             },
                //             {
                //                 code: 'no',
                //                 'title': 'Norwegian',
                //                 'nativetitle': 'Norsk'
                //             },
                //             {
                //                 code: 'ii',
                //                 'title': 'Nuosu',
                //                 'nativetitle': 'ꆈꌠ꒿ Nuosuhxop'
                //             },
                //             {
                //                 code: 'nr',
                //                 'title': 'South Ndebele',
                //                 'nativetitle': 'isiNdebele'
                //             },
                //             {
                //                 code: 'oc',
                //                 'title': 'Occitan',
                //                 'nativetitle': 'Occitan'
                //             },
                //             {
                //                 code: 'oj',
                //                 'title': 'Ojibwe, Ojibwa',
                //                 'nativetitle': 'ᐊᓂᔑᓈᐯᒧᐎᓐ'
                //             },
                //             {
                //                 code: 'cu',
                //                 'title': 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
                //                 'nativetitle': 'ѩзыкъ словѣньскъ'
                //             },
                //             {
                //                 code: 'om',
                //                 'title': 'Oromo',
                //                 'nativetitle': 'Afaan Oromoo'
                //             },
                //             {
                //                 code: 'or',
                //                 'title': 'Oriya',
                //                 'nativetitle': 'ଓଡ଼ିଆ'
                //             },
                //             {
                //                 code: 'os',
                //                 'title': 'Ossetian, Ossetic',
                //                 'nativetitle': 'ирон æвзаг'
                //             },
                //             {
                //                 code: 'pa',
                //                 'title': 'Panjabi, Punjabi',
                //                 'nativetitle': 'ਪੰਜਾਬੀ, پنجابی‎'
                //             },
                //             {
                //                 code: 'pi',
                //                 'title': 'Pāli',
                //                 'nativetitle': 'पाऴि'
                //             },
                //             {
                //                 code: 'fa',
                //                 'title': 'Persian',
                //                 'nativetitle': 'فارسی'
                //             },
                //             {
                //                 code: 'pl',
                //                 'title': 'Polish',
                //                 'nativetitle': 'polski'
                //             },
                //             {
                //                 code: 'ps',
                //                 'title': 'Pashto, Pushto',
                //                 'nativetitle': 'پښتو'
                //             },
                //             {
                //                 code: 'pt',
                //                 'title': 'Portuguese',
                //                 'nativetitle': 'Português'
                //             },
                //             {
                //                 code: 'qu',
                //                 'title': 'Quechua',
                //                 'nativetitle': 'Runa Simi, Kichwa'
                //             },
                //             {
                //                 code: 'rm',
                //                 'title': 'Romansh',
                //                 'nativetitle': 'rumantsch grischun'
                //             },
                //             {
                //                 code: 'rn',
                //                 'title': 'Kirundi',
                //                 'nativetitle': 'kiRundi'
                //             },
                //             {
                //                 code: 'ro',
                //                 'title': 'Romanian, Moldavian, Moldovan',
                //                 'nativetitle': 'română'
                //             },
                //             {
                //                 code: 'ru',
                //                 'title': 'Russian',
                //                 'nativetitle': 'русский язык'
                //             },
                //             {
                //                 code: 'sa',
                //                 'title': 'Sanskrit (Saṁskṛta)',
                //                 'nativetitle': 'संस्कृतम्'
                //             },
                //             {
                //                 code: 'sc',
                //                 'title': 'Sardinian',
                //                 'nativetitle': 'sardu'
                //             },
                //             {
                //                 code: 'sd',
                //                 'title': 'Sindhi',
                //                 'nativetitle': 'सिन्धी, سنڌي، سندھی‎'
                //             },
                //             {
                //                 code: 'se',
                //                 'title': 'Northern Sami',
                //                 'nativetitle': 'Davvisámegiella'
                //             },
                //             {
                //                 code: 'sm',
                //                 'title': 'Samoan',
                //                 'nativetitle': 'gagana faa Samoa'
                //             },
                //             {
                //                 code: 'sg',
                //                 'title': 'Sango',
                //                 'nativetitle': 'yângâ tî sängö'
                //             },
                //             {
                //                 code: 'sr',
                //                 'title': 'Serbian',
                //                 'nativetitle': 'српски језик'
                //             },
                //             {
                //                 code: 'gd',
                //                 'title': 'Scottish Gaelic; Gaelic',
                //                 'nativetitle': 'Gàidhlig'
                //             },
                //             {
                //                 code: 'sn',
                //                 'title': 'Shona',
                //                 'nativetitle': 'chiShona'
                //             },
                //             {
                //                 code: 'si',
                //                 'title': 'Sinhala, Sinhalese',
                //                 'nativetitle': 'සිංහල'
                //             },
                //             {
                //                 code: 'sk',
                //                 'title': 'Slovak',
                //                 'nativetitle': 'slovenčina'
                //             },
                //             {
                //                 code: 'sl',
                //                 'title': 'Slovene',
                //                 'nativetitle': 'slovenščina'
                //             },
                //             {
                //                 code: 'so',
                //                 'title': 'Somali',
                //                 'nativetitle': 'Soomaaliga, af Soomaali'
                //             },
                //             {
                //                 code: 'st',
                //                 'title': 'Southern Sotho',
                //                 'nativetitle': 'Sesotho'
                //             },
                //             {
                //                 code: 'es',
                //                 'title': 'Spanish; Castilian',
                //                 'nativetitle': 'español, castellano'
                //             },
                //             {
                //                 code: 'su',
                //                 'title': 'Sundanese',
                //                 'nativetitle': 'Basa Sunda'
                //             },
                //             {
                //                 code: 'sw',
                //                 'title': 'Swahili',
                //                 'nativetitle': 'Kiswahili'
                //             },
                //             {
                //                 code: 'ss',
                //                 'title': 'Swati',
                //                 'nativetitle': 'SiSwati'
                //             },
                //             {
                //                 code: 'sv',
                //                 'title': 'Swedish',
                //                 'nativetitle': 'svenska'
                //             },
                //             {
                //                 code: 'ta',
                //                 'title': 'Tamil',
                //                 'nativetitle': 'தமிழ்'
                //             },
                //             {
                //                 code: 'te',
                //                 'title': 'Telugu',
                //                 'nativetitle': 'తెలుగు'
                //             },
                //             {
                //                 code: 'tg',
                //                 'title': 'Tajik',
                //                 'nativetitle': 'тоҷикӣ, toğikī, تاجیکی‎'
                //             },
                //             {
                //                 code: 'th',
                //                 'title': 'Thai',
                //                 'nativetitle': 'ไทย'
                //             },
                //             {
                //                 code: 'ti',
                //                 'title': 'Tigrinya',
                //                 'nativetitle': 'ትግርኛ'
                //             },
                //             {
                //                 code: 'bo',
                //                 'title': 'Tibetan Standard, Tibetan, Central',
                //                 'nativetitle': 'བོད་ཡིག'
                //             },
                //             {
                //                 code: 'tk',
                //                 'title': 'Turkmen',
                //                 'nativetitle': 'Türkmen, Түркмен'
                //             },
                //             {
                //                 code: 'tl',
                //                 'title': 'Tagalog',
                //                 'nativetitle': 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔'
                //             },
                //             {
                //                 code: 'tn',
                //                 'title': 'Tswana',
                //                 'nativetitle': 'Setswana'
                //             },
                //             {
                //                 code: 'to',
                //                 'title': 'Tonga (Tonga Islands)',
                //                 'nativetitle': 'faka Tonga'
                //             },
                //             {
                //                 code: 'tr',
                //                 'title': 'Turkish',
                //                 'nativetitle': 'Türkçe'
                //             },
                //             {
                //                 code: 'ts',
                //                 'title': 'Tsonga',
                //                 'nativetitle': 'Xitsonga'
                //             },
                //             {
                //                 code: 'tt',
                //                 'title': 'Tatar',
                //                 'nativetitle': 'татарча, tatarça, تاتارچا‎'
                //             },
                //             {
                //                 code: 'tw',
                //                 'title': 'Twi',
                //                 'nativetitle': 'Twi'
                //             },
                //             {
                //                 code: 'ty',
                //                 'title': 'Tahitian',
                //                 'nativetitle': 'Reo Tahiti'
                //             },
                //             {
                //                 code: 'ug',
                //                 'title': 'Uighur, Uyghur',
                //                 'nativetitle': 'Uyƣurqə, ئۇيغۇرچە‎'
                //             },
                //             {
                //                 code: 'uk',
                //                 'title': 'Ukrainian',
                //                 'nativetitle': 'українська'
                //             },
                //             {
                //                 code: 'ur',
                //                 'title': 'Urdu',
                //                 'nativetitle': 'اردو'
                //             },
                //             {
                //                 code: 'uz',
                //                 'title': 'Uzbek',
                //                 'nativetitle': 'zbek, Ўзбек, أۇزبېك‎'
                //             },
                //             {
                //                 code: 've',
                //                 'title': 'Venda',
                //                 'nativetitle': 'Tshivenḓa'
                //             },
                //             {
                //                 code: 'vi',
                //                 'title': 'Viettitlese',
                //                 'nativetitle': 'Tiếng Việt'
                //             },
                //             {
                //                 code: 'vo',
                //                 'title': 'Volapük',
                //                 'nativetitle': 'Volapük'
                //             },
                //             {
                //                 code: 'wa',
                //                 'title': 'Walloon',
                //                 'nativetitle': 'Walon'
                //             },
                //             {
                //                 code: 'cy',
                //                 'title': 'Welsh',
                //                 'nativetitle': 'Cymraeg'
                //             },
                //             {
                //                 code: 'wo',
                //                 'title': 'Wolof',
                //                 'nativetitle': 'Wollof'
                //             },
                //             {
                //                 code: 'fy',
                //                 'title': 'Western Frisian',
                //                 'nativetitle': 'Frysk'
                //             },
                //             {
                //                 code: 'xh',
                //                 'title': 'Xhosa',
                //                 'nativetitle': 'isiXhosa'
                //             },
                //             {
                //                 code: 'yi',
                //                 'title': 'Yiddish',
                //                 'nativetitle': 'ייִדיש'
                //             },
                //             {
                //                 code: 'yo',
                //                 'title': 'Yoruba',
                //                 'nativetitle': 'Yorùbá'
                //             },
                //             {
                //                 code: 'za',
                //                 'title': 'Zhuang, Chuang',
                //                 'nativetitle': 'Saɯ cueŋƅ, Saw cuengh'
                //             }
                //         ]
                //     },
                // },
            ]
        }
    }
}

import {FormModelSchema} from '../../models/dynamic-compopent/form-model'

export const FORM_STORES_EDITOR: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'store',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Status',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 12,
                },
                // {
                //     title: 'Region',
                //     component: 'SelectOptionComponent',
                //     options: {
                //         remote: true,
                //         apiModel: {
                //             path: 'region',
                //             params: 'sort=title ASC'
                //         },
                //         value: 'name',
                //         defaultValue: 'region.name'
                //     },
                //     model: 'region',
                //     blockSize: 6
                // },
                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title',
                    blockSize: 12
                },
            ]
        },
        locations: {
            title: 'Location',
            fields: [
                {
                    title: 'Country',
                    component: 'InputComponent',
                    model: 'location.country',
                    blockSize: 6
                },
                {
                    title: 'Province',
                    component: 'InputComponent',
                    model: 'location.province',
                    blockSize: 6
                },
                {
                    title: 'City',
                    component: 'InputComponent',
                    model: 'location.city',
                    blockSize: 6
                },
                {
                    title: 'Address',
                    component: 'InputComponent',
                    model: 'location.address',
                    blockSize: 6
                },
                {
                    title: 'Zip Code',
                    component: 'InputComponent',
                    model: 'location.zipcode',
                    blockSize: 6
                },
                {
                    title: 'phone',
                    component: 'InputComponent',
                    model: 'location.phone',
                    blockSize: 6
                },

                {
                    title: 'Location',
                    component: 'GoogleMapComponent',
                    model: 'location.latlong',
                    blockSize: 12
                },
                // {
                //     model: 'location.type',
                //     type: 'hidden',
                //     value: 'Point',
                //     component: 'InputComponent',
                // }
            ]
        },
        schedule: {
            fields: [
                {
                    component: 'MultipleAddComponent',
                    model: 'schedule',
                    title: 'Schedule',
                    blockSize: 12,
                    multipleAdd: {
                        blockSize: 6,
                        buttonTitle: 'title:lang'
                    },
                    multipleComponentToUse: [
                        // {
                        //     title: 'Day',
                        //     component: 'InputComponent',
                        //     model: 'day',
                        //     blockSize: 12,
                        // },
                        {
                            title: 'Day',
                            component: 'SelectOptionComponent',
                            model: 'day',
                            options: {
                                values: [
                                    {
                                        id: 'Sunday',
                                        title: 'Sunday',
                                    },

                                    {
                                        id: 'Monday',
                                        title: 'Monday',
                                    },
                                    {
                                        id: 'Tuesday',
                                        title: 'Tuesday',
                                    },
                                    {
                                        id: 'Wednesday',
                                        title: 'Wednesday',
                                    },
                                    {
                                        id: 'Thursday',
                                        title: 'Thursday',
                                    },
                                    {
                                        id: 'Friday',
                                        title: 'Friday',
                                    },
                                    {
                                        id: 'Saturday',
                                        title: 'Saturday',
                                    },
                                ],
                                value: 'title',
                                defaultValue: 'day'
                            },
                            blockSize: 12
                        },
                        {
                            title: 'Open',
                            component: 'InputTimeComponent',
                            model: 'open',
                            blockSize: 6,
                        },
                        {
                            title: 'Close',
                            component: 'InputTimeComponent',
                            model: 'close',
                            blockSize: 6,
                        },
                    ]
                },
            ]
        },
        services: {
            title: 'Services',
            fields: [
                {
                    title: 'Drive Thru',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: [true, false]
                    },
                    model: 'services.drive_thru',
                    blockSize: 6,
                },
                {
                    title: 'Online Order',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: [true, false]
                    },
                    model: 'services.online_order',
                    blockSize: 6,
                }
            ]
        },
        gallery: {
            title: 'Gallery',
            fields: [
                {
                    title: 'Gallery',
                    component: 'ButtonFileComponent',
                    model: 'gallery',
                    blockSize: 12,
                    buttonFile: {
                        icon: 'icon-plus',
                        buttonType: 'image',
                        folderTarget: 'post',
                        multiple: true
                    }
                },
            ]
        }

    }
}

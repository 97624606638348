import {FormModelSchema} from '../../../../models/dynamic-compopent/form-model'

export const FORM_CLIENT_JCO_ROLE: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'role',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title',
                    blockSize: 6
                },

                {
                    title: 'Name',
                    component: 'InputComponent',
                    model: 'name',
                    blockSize: 6
                },

                {
                    title: 'Region',
                    component: 'SelectOptionComponent',
                    model: 'region',
                    options: {
                        remote: true,
                        apiModel: {
                            path: 'region',
                            params: 'sort=value ASC'
                        },
                        value: 'name',
                        setValue: 'id',
                        defaultValue: 'region.name'
                    },
                    blockSize: 12
                },
            ]
        }
    }
}

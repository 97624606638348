import {FormModelSchema} from '../../models/dynamic-compopent/form-model'

export const FORM_CATEGORY_BIMASENA: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'category',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 12,
                },

                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 12
                }
            ]
        }
    }
}

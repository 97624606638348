import {Injectable} from '@angular/core'
import {CrudService} from '../crud-service'
import {FileUploadOptionsModel} from '../../models/file-manager/file-upload-options.model'
import {LOOPING} from '../../helpers/helper-looping'
import {NotifDialogService} from '../notif-service'

@Injectable()
export class FileUploadService {

    maxFileSize: number = 10000000 // in byte

    constructor(private _crudService: CrudService,
                private _notifService: NotifDialogService) {

    }


    async uploadFile(options: any, type: string) {

        if (type.toLowerCase() === 'image') {
            return await this._upload(options, 'pic')
        } else {
            return await this._upload(options, 'doc')
        }
    }


    async uploadFileProject(options: FileUploadOptionsModel) {

        options.type = 'project'

        if (options.type.toLowerCase() === 'image') {
            return await this._upload(options)
        } else {
            return await this._uploadDocument(options)
        }

    }


    async uploadFileAvatar(options: FileUploadOptionsModel) {

        options.type = 'avatar'
        return await this._upload(options)

    }


    async uploadFileTestimonial(options: FileUploadOptionsModel) {

        options.type = 'testimonial'
        await this._upload(options)
    }


    private async _upload(data: any, type?: string) {

        let formData = new FormData()
        formData.append('type', data.type)

        LOOPING(data.file.target.files, (file) => {
            formData.append('file', file)
        })

        const upload: any = await this._crudService.POST(`upload/${type}`, formData)

        if (upload.files.length === 0) return

        return upload
    }

    private async _uploadDocument(data: any) {
        const upload: any = await this._crudService.POST('upload/skipper/doc', {
            type: data.type,
            file: data.file
        })

        if (!upload.success) return

        return upload
    }


    imageRestricted(file: any, strict: string, cb?: any) {

        if (strict === 'document' || strict === 'others') this.maxFileSize = 100000000

        return new Promise(resolve => {

            let isContinue = true

            if (!file) return resolve(false)

            if (file.target.files.length > 1) return resolve(isContinue)

            /**
             * For file size
             */
            if (file.target.files[0].size > this.maxFileSize) {
                this._notifService.showDialog({
                    type: 'error',
                    message: `Please upload image with max file size 2MB`
                })

                if (cb) cb()

                isContinue = false
            }


            /**
             * Strict type document
             */
            if (strict === 'document' && file.target.files[0].type !== 'application/pdf') {
                this._notifService.showDialog({
                    type: 'error',
                    message: 'PDF Only!'
                })

                if (cb) cb()

                isContinue = false
            }


            if (strict === 'image') {
                if (file.target.files[0].type !== 'image/jpeg' &&
                    file.target.files[0].type !== 'image/jpg' &&
                    file.target.files[0].type !== 'image/png') {
                    this._notifService.showDialog({
                        type: 'error',
                        message: 'Please upload image only!'
                    })

                    if (cb) cb()

                    isContinue = false
                }
            }

            if (strict === 'others') {
                if (file.target.files[0].type === 'image/jpeg' &&
                    file.target.files[0].type === 'image/jpg' &&
                    file.target.files[0].type === 'image/png') {
                    this._notifService.showDialog({
                        type: 'error',
                        message: 'Please upload document only!'
                    })

                    if (cb) cb()

                    isContinue = false
                }
            }

            resolve(isContinue)
        })
    }

}

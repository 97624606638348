export class NotifDialogModel {

    type: string
    message?: string
    cancelFn?: Function
    confirmFn?: Function
    timeOutClose?: number

}


export class ConfirmDialogModel {

}

import {CanActivate, Router} from '@angular/router'

import {Injectable} from '@angular/core'
import {StorageService} from '../services/storage-service'
import {UserService} from '../services/user/user-service'

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private _storageService: StorageService,
                private _router: Router,
                private _userService: UserService) {
    }

    async canActivate() {

        try {
            const valid: any = await this._userService.checkSelf()

            if (!this._storageService.get('auth') && !valid.success) {
                this._router.navigate(['/login'])
                this._storageService.remove('auth')
                this._storageService.remove('client_id')
                this._storageService.remove('language')
                return false
            }
        } catch (e) {
            this._router.navigate(['/login'])
            this._storageService.remove('auth')
            this._storageService.remove('client_id')
            return false
        }

        return true
    }

}

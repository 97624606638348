import {FormModelSchema} from '../../../models/dynamic-compopent/form-model'
import {DashboardNavigationManagerComponent} from '../../../components/dashboard-navigation-manager/dashboard-navigation-manager.component'


export const VIP_CLIENT_FORM_EDITOR: FormModelSchema = {
    pathModel: 'client',
    baseRoute: 'vip',
    singleData: true,
    component: DashboardNavigationManagerComponent,
    group: {
        formProfileDetail: {
            title: 'Create Client',
            fields: [
                {
                    title: 'Active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: [true, false]
                    },
                    model: 'is_active',
                    blockSize: 12,
                },

                {
                    title: 'Client Name',
                    component: 'InputComponent',
                    model: 'name',
                    blockSize: 6
                },

                {
                    title: 'Client Url',
                    component: 'InputComponent',
                    model: 'url',
                    blockSize: 6
                },

                {
                    title: 'Implement',
                    component: 'CheckBoxComponentComponent',
                    model: 'implement',
                    checkBox: {
                        remote: true,
                        apiModel: {
                            path: 'service'
                        },
                        label: 'name',
                        setValueModal: 'id'
                    }
                },
            ],
        }
    }
}

import {FormModelSchema} from '../../../models/dynamic-compopent/form-model'

export const FORM_CLIENT_JCO_USER: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'user',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Is active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: [true, false]
                    },
                    model: 'is_active',
                    blockSize: 6,
                },

                {
                    title: 'Name',
                    component: 'InputComponent',
                    model: 'name',
                    blockSize: 6
                },

                {
                    title: 'User Name',
                    component: 'InputComponent',
                    model: 'username',
                    blockSize: 6
                },

                {
                    title: 'Email',
                    component: 'InputComponent',
                    model: 'email',
                    blockSize: 6
                },

                {
                    title: 'Role',
                    component: 'SelectOptionComponent',
                    model: 'role',
                    options: {
                        remote: true,
                        apiModel: {
                            path: 'role',
                            params: 'sort=value ASC'
                        },
                        value: 'title',
                        setValue: 'id',
                        defaultValue: 'role.title'
                    },
                    blockSize: 6
                },
                
                {
                    title: 'Region',
                    component: 'SelectOptionComponent',
                    model: 'region',
                    options: {
                        remote: true,
                        apiModel: {
                            path: 'region',
                            params: 'sort=value ASC'
                        },
                        value: 'name',
                        setValue: 'id',
                        defaultValue: 'region.name'
                    },
                    blockSize: 6
                },
            ]
        }
    }
}

import {FormModelSchema} from '../../models/dynamic-compopent/form-model'

export const FORM_STORY_EDITOR: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'story',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 12,
                },

                {
                    title: 'Title One',
                    component: 'InputComponent',
                    model: 'title.one:lang',
                    blockSize: 4
                },

                {
                    title: 'Title Two',
                    component: 'InputComponent',
                    model: 'title.two:lang',
                    blockSize: 4
                },

                {
                    title: 'Title Three',
                    component: 'InputComponent',
                    model: 'title.three:lang',
                    blockSize: 4
                },

                {
                    title: 'Year',
                    component: 'InputComponent',
                    model: 'year',
                    type: 'number',
                    blockSize: 12
                },

                {
                    title: 'Body',
                    component: 'TextEditorComponent',
                    model: 'body:lang',
                    blockSize: 12
                },
            ]
        },

        projectCost: {
            fields: [
                {
                    title: 'Featured',
                    component: 'ButtonFileComponent',
                    model: 'featured',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 6
                },
            ]
        },
    }
}

import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {NotifDialogComponent} from './notif-dialog.component'

@NgModule({
    imports: [
        CommonModule
    ],

    exports: [
        NotifDialogComponent
    ],

    declarations: [
        NotifDialogComponent
    ]
})
export class NotifDialogModule {
}

import * as _ from 'lodash'
import * as $UID from 'uuid/v4'
import {ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injector, Type} from '@angular/core'

export function htmlToPlaintext(text) {


    const findLink = /<a [^<$]+/g

    /**
     * Remove link
     * @type {string | any | void}
     */
    if (text) {
        text = text.replace(findLink, '')
        text = String(text).replace(/<[^>]+>/gm, '')
    }

    return text
}


export function FindTag(regex: string, str: string) {
    /**
     * Find Tag
     * @type {RegExp}
     */
    const findRegex = RegExTag(regex)
    return ((str || '').match(findRegex) || []).length
}


export function RegExTag(regex: string): any {
    return new RegExp(regex + '="(.*?)"', 'g')
}


export function CreateDom(id: string, styleClass?: string, selector: string = 'div') {
    const container = document.createElement(selector)

    if (styleClass) container.classList.add(styleClass)
    container.setAttribute('id', id)
    return container
}


export function CloneDeepData(cloning: any) {

    return _.cloneDeep(cloning)
}

export function ObjectIndex(obj, is, value?) {
    if (typeof is == 'string') return ObjectIndex(obj, is.split('.'), value)
    else if (is.length == 1 && value !== undefined) {
        return obj[is[0]] = value
    }
    else if (is.length == 0) return obj
    else return ObjectIndex(obj[is[0]], is.slice(1), value)
}


export function ArrayMove(arr, fromIndex, toIndex, insert?: boolean) {
    const element = arr[fromIndex]
    arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, element)

    return arr
}


export function ArrayInsert(arr: any[], insertTo: number, item: any) {
    return arr.splice(insertTo, 0, item)
}


export function GetIndexDom(dom) {
    const parent = dom.parentNode
    const components = Array.prototype.slice.call(parent.children)
    const index = components.indexOf(dom)
    return index
}


export function RecursiveChecker(checker: any) {
    const checkExist = setInterval(() => {
        if (checker) {
            console.log('Exists!')
            clearInterval(checkExist)
        }
    }, 100)
}

export function ScrollTo(element, to, duration) {


    // scrollTop(element, to, duration)
    // let start = element.scrollTop,
    //     change = to - start,
    //     currentTime = 0,
    //     increment = 20
    //
    // const animateScroll = () => {
    //     currentTime += increment
    //     const val = Math.easeInOutQuad(currentTime, start, change, duration)
    //     element.scrollTop = val
    //
    //     if (currentTime < duration) {
    //         setTimeout(animateScroll(), increment)
    //     }
    // }
}


export function AddComponentToBody(factory: ComponentFactoryResolver, appRef: ApplicationRef, component: Type<any>, injector: Injector) {

    const componentId = $UID()

    const componentRef = factory
        .resolveComponentFactory(component)
        .create(injector)

    /**
     * Attach component to the appRef so that it's inside the ng component tree
     */
    appRef.attachView(componentRef.hostView)

    /**
     * Get DOM element from component
     * @type {HTMLElement}
     */
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement

    document.body.appendChild(domElem)

    return {
        componentId: componentId,
        application: appRef,
        compRef: componentRef
    }
}

import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    Input,
    OnInit,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core'
import {TimeoutWait} from '../../helpers/helper-async-timeout'

@Component({
    selector: 'arv-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, AfterViewInit {

    showModule: boolean
    injectedComponent: any
    newComponent: any

    @ViewChild('containerRef', {read: ViewContainerRef}) containerRef: ViewContainerRef
    @ViewChild('containerModal') containerModal: ElementRef
    @Input('disableButtonClose') disableButtonClose: boolean


    constructor(private _FactoryResolver: ComponentFactoryResolver) {
    }

    ngOnInit() {


    }

    async ngAfterViewInit() {
        await TimeoutWait(300)
    }

    async createModalContainer(comp: Type<any>) {

        if (this.newComponent) this.newComponent.clear()

        const resolver = await this._FactoryResolver.resolveComponentFactory(comp)
        this.showModule = true

        await TimeoutWait(800)
        this.containerModal.nativeElement.classList.add('fade-in')
        return this.newComponent = this.containerRef.createComponent(resolver)


    }


    replaceComponent(comp: Type<any>) {

        /**
         * Destroy the previous component
         */
        this.injectedComponent.destroy()


        const resolver = this._FactoryResolver.resolveComponentFactory(comp)
        this.injectedComponent = this.containerRef.createComponent(resolver)
    }


    closeModalComponent() {
        this.showModule = false
        this.newComponent.destroy()
    }

}

import {NavigationModel} from '../navigation-model'

export class DashboardNavigationSchema {
    constructor(id?: string) {
        if (id) this.id = id
    }

    title?: string
    path?: string
    id?: string
    component?: any
    status?: string
    data?: DashboardNavigationData = new DashboardNavigationData()
    children?: NavigationModel[]
}


export class DashboardNavigationData {

    title?: string
    icon?: string
    role?: string[]
    tableSetting?: string
    cardDetail?: string
    editorRoutePath?: string
    headingTitle?: string
    formDetail?: string
}

export const LOOPING = (data: any[], cb: any) => {

    if (!data) return


    for (let i = 0; i < data.length; i++) {

        const result = data[i]

        if (cb) cb(result, i)

    }

}


export const LOOPING_ASYNC = (data: any[], cb: any) => {

    if (!data) return


    data.forEach((data, index) => {
        if (cb) cb(data, index)
    })

}

import {FormModelSchema} from '../../models/dynamic-compopent/form-model'

export const FORM_GALLERY_SUGARSAND: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'gallery',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 12,
                },

                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 12
                },
            ]
        },

        gallery: {
            fields: [
                {
                    title: 'Gallery',
                    component: 'ButtonFileComponent',
                    model: 'pictures',
                    blockSize: 12,
                    buttonFile: {
                        icon: 'icon-plus',
                        buttonType: 'image',
                        folderTarget: 'post',
                        multiple: true
                    }
                },
            ]
        },
    }
}

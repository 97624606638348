import {Injectable} from '@angular/core'
import {Subject} from 'rxjs/Rx'
import {environment} from '../../environments/environment'
import {SALT_KEY} from '../cores/builder/constants/pb-constant'

@Injectable()
export class IframeDataService {

    private _$listenPostMessage: Subject<any> = new Subject<any>()
    private _$listenData: Subject<any>[] = []


    /**
     * Store all action key from client
     * @param {string} key
     * @param message
     */
    iframeClientActionKey: any


    sendMessage(key: string, message: any = null) {
        this._$listenPostMessage.next({
            key,
            message
        })
    }


    getPostMessage() {
        return this._$listenPostMessage.asObservable()
    }


    $setListener(key: string, value: any) {
        this._$listenData[key].next(value)
    }


    $listenSubject(key: string) {
        if (!this._$listenData[key]) this._$listenData[key] = new Subject<any>()
        return this._$listenData[key].asObservable()
    }

    sendMessageToParent(key, message) {
        window.parent.postMessage({
            key: key,
            message: message,
            salt: SALT_KEY
        }, environment.access_origin)
    }

}

import {FormModelSchema} from '../../../models/dynamic-compopent/form-model'


export const FORM_CLIENT_USERS: FormModelSchema = {
    pathModel: 'user',
    baseRoute: 'vip',
    singleData: true,
    group: {
        formProfileDetail: {
            title: 'User Settings',
            fields: [
                {
                    title: 'Active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: [true, false]
                    },
                    model: 'is_active',
                    blockSize: 12,
                },
                {
                    component: 'InputComponent',
                    title: 'Title',
                    model: 'name',
                    blockSize: 6
                },

                {
                    component: 'InputComponent',
                    title: 'Title',
                    model: 'name',
                    blockSize: 6
                },

                {
                    component: 'InputComponent',
                    title: 'Title',
                    model: 'name',
                    blockSize: 6
                },

                {
                    title: 'Role',
                    component: 'SelectOptionComponent',
                    options: {
                        remote: true,
                        apiModel: {
                            path: 'role',
                            params: 'sort=title ASC'
                        },
                        value: 'name',
                        defaultValue: 'client.name'
                    },
                    model: 'role',
                    blockSize: 6
                },

                {
                    title: 'Client',
                    component: 'SelectOptionComponent',
                    options: {
                        remote: true,
                        apiModel: {
                            path: 'client',
                            params: 'sort=title ASC'
                        },
                        value: 'name',
                        defaultValue: 'client.name'
                    },
                    model: 'client',
                    blockSize: 6
                },

                {
                    component: 'InputComponent',
                    title: 'Region',
                    model: 'region',
                    blockSize: 6
                },
            ]
        }
    }
}

export const ADD_MORE_SECTION = 'ADD_MORE_SECTION'
export const INSERT_SECTION = 'INSERT_SECTION'
export const DUPLICATE_SECTION = 'DUPLICATE_SECTION'
export const RE_INIT_SCRIPT = 'RE_INIT_SCRIPT'
export const DELETE_SECTION = 'DELETE_SECTION'
export const REQ_REGISTERED_SECTIONS = 'REQ_REGISTERED_SECTIONS'
export const REQ_BUILDER_FORM_DATA = 'REQ_BUILDER_FORM_DATA'
export const GET_BUILDER_FORM_DATA = 'GET_BUILDER_FORM_DATA'
export const GET_REGISTERED_SECTIONS = 'GET_REGISTERED_SECTIONS'
export const SORT_WEBSITE_SECTION = 'SORT_WEBSITE_SECTION'

export const RELOAD_PB = 'RELOAD_PB'
export const SAVE_PB_DATA = 'SAVE_PB_DATA'
export const ACCESS_FILE_MANAGER = 'ACCESS_FILE_MANAGER'
export const SELECTED_FILE = 'SELECTED_FILE'
export const BUILDER_READY = 'BUILDER_READY'

export const NAVIGATE_BACK = 'NAVIGATE_BACK'
export const CHECK_CONTENT = 'CHECK_CONTENT'


export const SALT_KEY = '*=_H1Q*`41KZ9?-Vp|l<!-]|:w+PI+xh$cuW3w#DZ-2X@CeV.vMXh^]qB4RK1Cw+'

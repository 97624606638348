import {FormModelSchema} from '../../models/dynamic-compopent/form-model'

export const FORM_JCO_CAREERS: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'career',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 6,
                },
                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 12
                },

                {
                    title: 'Province',
                    component: 'InputComponent',
                    model: 'location.province',
                    blockSize: 6
                },

                {
                    title: 'City',
                    component: 'InputComponent',
                    model: 'location.city',
                    blockSize: 6
                },

                {
                    title: 'Address',
                    component: 'InputComponent',
                    model: 'location.address',
                    blockSize: 6
                },

                {
                    title: 'Email Contact',
                    component: 'InputComponent',
                    type: 'email',
                    model: 'email_to',
                    blockSize: 6
                },
                {
                    title: 'Deadline Date',
                    component: 'InputCalendarComponent',
                    inputCalendar: {
                        blockSize: 9,
                        startDate: true,
                        dateFormat: 'dd/MM/yyyy'
                    },
                    model: 'meta.deadline,%d',
                    blockSize: 6
                },
                {
                    title: 'Type',
                    component: 'SelectOptionComponent',
                    model: 'meta.type',
                    options: {
                        values: [
                            {
                                id: 'Full Time Employment',
                                title: 'Full Time Employment',
                            },
                            {
                                id: 'Half Time Employment',
                                title: 'Half Time Employment'
                            },
                            {
                                id: 'Freelance',
                                title: 'Freelance',
                            },
                            {
                                id: 'Internship',
                                title: 'Internship',
                            },
                        ],
                        value: 'title',
                        defaultValue: 'meta.type'
                    },
                    blockSize: 6
                },
                {
                    title: 'Body',
                    component: 'TextEditorComponent',
                    model: 'body:lang',
                    blockSize: 12
                },
            ]
        },
        // sections: {
        //     fields: [
        //         {
        //             component: 'MultipleAddComponent',
        //             model: 'body:lang.description',
        //             title: 'Description Detail',
        //             blockSize: 12,
        //             multipleAdd: {
        //                 blockSize: 6,
        //                 buttonTitle: 'title:lang'
        //             },
        //             multipleComponentToUse: [
        //                 {
        //                     title: 'Title',
        //                     component: 'InputComponent',
        //                     model: 'title',
        //                     blockSize: 12,
        //                 },
        //                 {
        //                     title: 'Content Description',
        //                     component: 'TextEditorComponent',
        //                     model: 'content',
        //                     blockSize: 12,
        //                 }
        //             ]
        //         }
        //     ]
        // }
    }
}

import {FormModelSchema} from '../../../models/dynamic-compopent/form-model'
import {CheckBoxComponentComponent} from '../../../cores/creators/form-components/check-box-component/check-box-component.component'


export const FORM_USER_ACP: FormModelSchema = {
    pathModel: 'acp',
    baseRoute: 'vip',
    singleData: true,
    group: {
        formProfileDetail: {
            title: 'Manage Client ACP',
            fields: [
                {
                    title: 'Is Public',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: [true, false]
                    },
                    model: 'is_public',
                    blockSize: 12,
                },

                {
                    title: 'Action',
                    component: 'InputComponent',
                    model: 'action',
                    blockSize: 6
                },

                {
                    title: 'Allowed',
                    component: 'CheckBoxComponentComponent',
                    checkBox: {
                        remote: true,
                        apiModel: {
                            path: 'role'
                        },
                        label: 'name' || 'title',
                        valueCheck: 'id',
                        setValueModal: 'id',
                    },
                    model: 'allowed',
                    blockSize: 12
                },
            ],
        }
    }
}

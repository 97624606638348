import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {ModalComponent} from './modal.component'
import {ModalPopupService} from './service/modal.service'

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        ModalComponent
    ],
    declarations: [
        ModalComponent
    ],

    entryComponents: [
        ModalComponent
    ],

    providers: [
        ModalPopupService
    ]
})
export class ModalModule {
}

import {Injectable} from '@angular/core'
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import 'rxjs/add/operator/do'
import {Router} from '@angular/router'
import {StorageService} from '../services/storage-service'
import {NotifDialogService} from '../services/notif-service'
import {Observable} from 'rxjs/Rx'

@Injectable()
export class HTTPAuthInterceptor implements HttpInterceptor {

    constructor(private _storage: StorageService,
                private _notifDialog: NotifDialogService,
                private _router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const jwt = this._storage.get('auth')

        if (jwt) {
            req = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${jwt}`),
            })
        }

        return next.handle(req).do(event => {


        }, err => {


            if (err.status === 404) {
                console.log('Route not found')
                return

            }

            if (err.status === 400) {

                this._notifDialog.showDialog({
                    type: 'error',
                    message: 'Error, ' + err.error.summary + '!',
                    timeOutClose: 4000
                })
            }


            if (err.status === 502 || err.status === 401) {
                this._storage.remove('auth')
                this._router.navigate(['/login'])
            }

        })
    }
}

import {Injectable} from '@angular/core'
import {Subject} from 'rxjs/Subject'
import {CardLayoutSchema} from '../../models/dynamic-compopent/card-model'

@Injectable()
export class CreateCardComponentService {

    private _$listenCardData: Subject<CardLayoutSchema> = new Subject<CardLayoutSchema>()

    private _$listenCardDataChange: Subject<any> = new Subject<any>()
    private _$listenCardDataCreated: Subject<any> = new Subject<any>()

    modelDefaultParams = {
        limit: '999999',
        sort: 'created_at DESC'
    }

    activeParams: any = {}

    cardDetail: CardLayoutSchema = new CardLayoutSchema()
    cardData: any = []


    $setCardDetail(cardDetail: CardLayoutSchema) {
        this.cardDetail = cardDetail
        this._$listenCardData.next(cardDetail)

    }

    $getCardDetail() {
        return this._$listenCardData.asObservable()
    }

    $setSingleCardData(data) {
        this.cardData.push(data)
        this._$listenCardDataCreated.next(data)
    }

    $getNewCardCreatedData() {
        return this._$listenCardDataCreated.asObservable()
    }

    $setCardData(data) {
        this.cardData = data
        this._$listenCardDataChange.next(data)
    }

    $getUpdatedCardData() {
        return this._$listenCardDataChange.asObservable()
    }


}

import {AfterViewInit, Component, OnInit} from '@angular/core'
import {DashboardNavigationSchema} from '../../models/dashboard-navigation-schema/dashboard-navigation-schema'
import {EditorDataService} from '../../services/editor/editor-data-service'
import * as $UID from 'uuid/v4'
import {ModalPopupService} from '../../cores/modal/service/modal.service'
import {DashboardNavigationEditorComponent} from './dashboard-navigation-editor/dashboard-navigation-editor.component'
import {TimeoutWait} from '../../helpers/helper-async-timeout'

@Component({
    selector: 'vold-dashboard-navigation-manager',
    templateUrl: './dashboard-navigation-manager.component.html',
    styleUrls: ['./dashboard-navigation-manager.component.scss']
})
export class DashboardNavigationManagerComponent implements OnInit, AfterViewInit {
    createNavigation: boolean
    animate: boolean
    navigation: DashboardNavigationSchema[] = []
    dashboardNavigationModel: DashboardNavigationSchema = new DashboardNavigationSchema()

    constructor(private _editorDataService: EditorDataService,
                private _modalService: ModalPopupService) {
    }

    ngOnInit() {
        if (!this._editorDataService.singleData['dashboard_navigation']) this._editorDataService.singleData['dashboard_navigation'] = []
        this.navigation = this._editorDataService.singleData.dashboard_navigation
    }


    ngAfterViewInit() {

    }

    async newNavigation() {

        if (this.createNavigation) {
            this.animate = false
            await TimeoutWait(200)
            this.createNavigation = false
            return
        }

        this.createNavigation = true
        this.dashboardNavigationModel = new DashboardNavigationSchema($UID())
        await TimeoutWait(200)
        this.animate = true
    }

    async manageNavigation(nav: DashboardNavigationSchema) {
        const editor = await this._modalService.injectToBody(DashboardNavigationEditorComponent)
        editor.instance.detail = nav
        editor.instance.detail.client_id = this._editorDataService.singleData.id
    }


    deleteNavigation(index: number) {
        this.navigation.splice(index, 1)
    }

    createNavigationData() {
        this.animate = false
        this.createNavigation = false
        this.navigation.push(this.dashboardNavigationModel)
        this.dashboardNavigationModel = null
    }

}

import {FormModelSchema} from '../../models/dynamic-compopent/form-model'

export const FORM_CAREERS: FormModelSchema = {
    baseRoute: 'manage',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 12,
                },

                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 12
                },

                // {
                //     title: 'Country',
                //     component: 'InputComponent',
                //     model: 'location.country',
                //     blockSize: 4
                // },

                {
                    title: 'Province',
                    component: 'InputComponent',
                    model: 'location.province',
                    blockSize: 6
                },

                {
                    title: 'City',
                    component: 'InputComponent',
                    model: 'location.city',
                    blockSize: 6
                },

                {
                    title: 'Address',
                    component: 'InputComponent',
                    model: 'location.address',
                    blockSize: 5
                },

                {
                    title: 'Building',
                    component: 'InputComponent',
                    model: 'location.building',
                    blockSize: 5
                },

                {
                    title: 'Zip Code',
                    component: 'InputComponent',
                    type: 'number',
                    model: 'location.zipcode',
                    blockSize: 2
                },

                {
                    title: 'Email Contact',
                    component: 'InputComponent',
                    type: 'email',
                    model: 'email_to',
                    blockSize: 12
                },

                {
                    title: 'Body',
                    component: 'TextEditorComponent',
                    model: 'body:lang',
                    blockSize: 12
                },

            ]
        },

        projectCost: {
            fields: [
                {
                    title: 'Featured',
                    component: 'ButtonFileComponent',
                    model: 'featured',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 6
                },
                {
                    title: 'Og Image',
                    component: 'ButtonFileComponent',
                    model: 'og_image',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 6
                },
            ]
        },
    }
}

import {NgModule} from '@angular/core'
import {FileUploadService} from './file-upload.service'
import {FileManagerService} from './file-manager.service'

@NgModule({
    providers: [
        FileUploadService,
        FileManagerService
    ],
})

export class FileManagerBundleModule {

}

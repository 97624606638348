import {AuthGuard} from './guards/auth-guard'
import {DashboardResolver} from './resolver/dashboard-resolver'

export const PAGES_PATH = './pages/'

export const AppRoutes = [
    {
        path: 'login',
        loadChildren: `${PAGES_PATH}login/login.module#LoginModule`
    },

    {
        path: 'p',
        loadChildren: `${PAGES_PATH}dashboard/container/container.module#DashboardContainerModule`,
        resolve: {
            dashboard: DashboardResolver
        },
        canActivate: [AuthGuard]
    },

    {
        path: '**',
        redirectTo: 'p/home',
        pathMatch: 'full'
    }
]

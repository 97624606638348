import { FormModelSchema } from '../../models/dynamic-compopent/form-model'

export const FORM_PRODUCT_CATEGORY: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'category',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Status',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 6,
                },
                {
                    title: 'Type',
                    component: 'SelectOptionComponent',
                    model: 'meta.type',
                    options: {
                        values: [
                            {
                                id: 'Food',
                                title: 'Food',
                            },

                            {
                                id: 'Beverage',
                                title: 'Beverage',
                            }
                        ],
                        value: 'title',
                        defaultValue: 'meta.type'
                    },
                    blockSize: 6
                },
                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 12
                },
                {
                    title: 'Description',
                    component: 'TextEditorComponent',
                    model: 'body:lang',
                    blockSize: 12
                },
                {
                    title: 'Featured',
                    component: 'ButtonFileComponent',
                    model: 'featured',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 6
                },
            ]
        }
    }
}

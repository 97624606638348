import {Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core'
import {DashboardNavigationSchema} from '../../../models/dashboard-navigation-schema/dashboard-navigation-schema'
import {VIP_DASHBOARD_NAVIGATION_EDITOR} from '../../../forms/editor/vip/vip-form-dashboard-navigation-editor'
import {ModalPopupService} from '../../../cores/modal/service/modal.service'
import {VIP_TABLE_LAYOUT_EDITOR} from '../../../forms/editor/vip/vip-form-dashboard-navigation_card_layout_config-editor'

@Component({
    selector: 'vold-dashboard-navigation-editor',
    templateUrl: './dashboard-navigation-editor.component.html',
    styleUrls: ['./dashboard-navigation-editor.component.scss']
})
export class DashboardNavigationEditorComponent implements OnInit {

    detail: DashboardNavigationSchema
    formDetail = VIP_DASHBOARD_NAVIGATION_EDITOR
    tableDetail = VIP_TABLE_LAYOUT_EDITOR
    selectedForm: number
    @ViewChildren('formContainer') formContainer: QueryList<ElementRef>

    constructor(private _modalService: ModalPopupService) {
    }

    ngOnInit() {

    }

    switchLayout(index: number) {
        this.selectedForm = index
        this.formContainer.toArray().map((form, i) => {
            form.nativeElement.classList.remove('active')
            if (index === i) form.nativeElement.classList.add('active')
        })
    }

    updateSettings() {
        this._modalService.closeModal()
    }
}

import {NgModule} from '@angular/core'
import {CrudService} from './crud-service'
import {StorageService} from './storage-service'
import {EditorService} from './editor/editor-service'
import {EditorDataService} from './editor/editor-data-service'
import {EditorComponentService} from './editor/editor-component-service'
import {UserService} from './user/user-service'
import {FileManagerBundleModule} from './file-manager/file-manager-bundle.module'
import {CalendarService} from './calendar/calendar.service'
import {NotifDialogService} from './notif-service'
import {CardExpandService} from './card-expand.service'
import {CreateNewPostService} from './create/create-new-post.service'
import {AnimationService} from './animation.service'
import {ModalModule} from '../cores/modal/modal.module'
import {CreateCardComponentService} from './create/create-card-component.service'
import {ModalPopupService} from '../cores/modal/service/modal.service'
import {ElasticDataService} from './elastic/elastic-data-service'
import {DragDropService} from './drag-drop/drag-drop.service'
import {FilterService} from './filter/filter.service'
import {InfiniteScrollService} from './infinite-scroll/infinite-scroll.service'
import {IframeDataService} from './iframe-data.service'
import {SubjectsService} from './subjects.service'

@NgModule({

    imports: [
        ModalModule,
        FileManagerBundleModule
    ],

    exports: [
        ModalModule,
        FileManagerBundleModule
    ],

    providers: [
        CrudService,
        StorageService,
        EditorService,
        EditorDataService,
        EditorComponentService,
        UserService,
        CalendarService,
        NotifDialogService,
        CardExpandService,
        CreateNewPostService,
        AnimationService,
        ModalPopupService,
        CreateCardComponentService,
        ElasticDataService,
        DragDropService,
        FilterService,
        InfiniteScrollService,
        IframeDataService,
        SubjectsService
    ]
})

export class ServicesBundleModule {

}

import {CalendarCore, DateDateModel} from './calendar-core'

export class Calendar extends CalendarCore {

    dates = []

    date = new Date()

    constructor() {
        super()
    }

    createDates(year: number = this.date.getFullYear(), month: number = this.date.getMonth()) {


        this.date.setFullYear(year)
        this.date.setMonth(month)

        this.currentSelectedYear = year
        this.currentSelectedMonth = month
        this.currentSelectedMonthIndex = month
        return new Promise(resolve => {
            resolve(this._generateCalendarArray())
        })

    }


    private _generateCalendarArray() {

        let dates: any = []


        /**
         * Get first day of current month
         */
        const firstDateOfMonth = new Date(this.currentSelectedYear, this.currentSelectedMonth, 1)
        const lastDateOfMonth = new Date(this.currentSelectedYear, this.currentSelectedMonth + 1, 0)
        const firstDayOfCurrentMonth = firstDateOfMonth.getDay()

        /**
         * Dates of current month
         */
        for (let i = 1; i <= lastDateOfMonth.getDate(); i++) {

            const date = new Date(this.currentSelectedYear, this.currentSelectedMonth, i)

            const today = new Date().setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0)

            dates.push(this._setDateData(date, today))

        }


        /**
         * Passed dates of previous month
         */
        for (let i = 0; i < firstDayOfCurrentMonth; i++) {

            const date = new Date(this.currentSelectedYear, this.currentSelectedMonth, 0)

            date.setDate(date.getDate() - i)
            dates.unshift(this._setDateData(date))

        }


        /**
         * Next dates of next month
         */
        if (dates.length < 42) {

            const restDates = 42 - dates.length

            for (let i = 1; i <= restDates; i++) {

                const date = new Date(this.currentSelectedYear, this.currentSelectedMonth + 1, 0)

                date.setDate(date.getDate() + i)

                dates.push(this._setDateData(date))

            }
        }


        /**
         * Loop and give week group
         */
        dates.forEach((date: any, index: number) => {

            date['week'] = Math.floor(index / 7) + 1

        })

        return {
            month: this.months[this.currentSelectedMonth],
            year: this.currentSelectedYear,
            dates
        }


    }


    private _setDateData(date: Date, today: boolean = false): DateDateModel {

        const dateOfCurrentMonth = this.date.getMonth() === date.getMonth()

        return {
            dateStamp: date,
            today,
            day: date.getDay(),
            date: date.getDate(),
            dateOfCurrentMonth: dateOfCurrentMonth,
            year: date.getFullYear(),
            monthName: this.months[date.getMonth()],
            monthIndex: date.getMonth()
        }
    }

    async getNextMontDates(m?, y?) {
        this.nextMonth()
        return await this._generateCalendarArray()
    }

    async getPrevMontDates(m?, y?) {
        this.prevMonth()
        return await this._generateCalendarArray()
    }
}

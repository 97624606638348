import {FormModelSchema} from '../../../models/dynamic-compopent/form-model'

export const VIP_TABLE_LAYOUT_EDITOR: FormModelSchema[] = [
    {
        title: 'Api Model',
        component: 'InputComponent',
        model: 'data.cardDetail.apiModel.path',
        blockSize: 4
    },

    {
        title: 'Sort By',
        component: 'InputComponent',
        model: 'data.cardDetail.apiModel.params.sort',
        blockSize: 4
    },

    {
        title: 'Limit',
        component: 'InputComponent',
        model: 'data.cardDetail.apiModel.limit',
        blockSize: 4
    },

    {
        title: 'Editor Path Route',
        component: 'InputComponent',
        model: 'data.cardDetail.editor.prefixPath',
        blockSize: 6
    },
    {
        title: 'Search In Model',
        component: 'InputComponent',
        model: 'data.cardDetail.model.cardTable.searchInModel',
        blockSize: 6
    },
    {
        title: 'Table Header',
        component: 'InputComponent',
        model: 'data.cardDetail.model.cardTable.header',
        blockSize: 3
    },

    {
        title: 'Table Filter',
        component: 'InputComponent',
        model: 'data.cardDetail.model.cardTable.filterByHeader',
        blockSize: 3
    },

    {
        title: 'Table Data Property',
        component: 'InputComponent',
        model: 'data.cardDetail.model.cardTable.data',
        blockSize: 3
    },

    {
        title: 'Table Col Size',
        component: 'InputComponent',
        model: 'data.cardDetail.model.cardTable.colSize',
        blockSize: 3
    }
]

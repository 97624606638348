import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router'

import {Injectable} from '@angular/core'
import {StorageService} from '../services/storage-service'
import {UserService} from '../services/user/user-service'
import {LOOPING} from '../helpers/helper-looping'

@Injectable()
export class UserRoleChecker implements CanActivate {
    constructor(private _storageService: StorageService,
                private _router: Router,
                private _activateRoute: ActivatedRoute,
                private _userService: UserService) {

    }

    async canActivate(route: ActivatedRouteSnapshot) {

        const hasPermission: boolean = await this._roleChecker(route)


        if (!hasPermission) {
            this._router.navigate(['p/home'])
            return false
        }

        return true
    }


    private _roleChecker(route): Promise<boolean> {

        let hasPermission: boolean = false

        return new Promise(resolve => {
            LOOPING(route.data.role, role => {
                const self = this._userService.user

                if (role === self.role.title) {
                    hasPermission = true
                    return
                }
            })

            resolve(hasPermission)
        })
    }

}

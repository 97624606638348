import { FormModelSchema } from '../../models/dynamic-compopent/form-model'

export const FORM_EVENT_SUGARSAND: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'event',
    group: {
        forms: {
            fields: [
                {
                    title: 'Active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 12,
                },

                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 12
                },

                {
                    title: 'Event Date',
                    component: 'InputCalendarComponent',
                    inputCalendar: {
                        blockSize: 9,
                        startDate: true,
                        dateFormat: 'dd/MM/yyyy'
                    },
                    model: 'datetime,%d',
                    blockSize: 6
                },

                {
                    title: 'Start Time',
                    component: 'InputTimeComponent',
                    model: 'time',
                    blockSize: 6
                },

                {
                    title: 'Body',
                    component: 'TextEditorComponent',
                    model: 'body:lang',
                    blockSize: 12
                },
            ]
        },

        links: {
            fields: [
                {
                    title: 'Links',
                    component: 'MultipleAddComponent',
                    model: 'links',
                    multipleAdd: {
                        blockSize: 12,
                        buttonTitle: 'title'
                    },
                    multipleComponentToUse: [
                        {
                            title: 'Title',
                            component: 'InputComponent',
                            model: 'title',
                        },

                        {
                            title: 'URL',
                            component: 'InputComponent',
                            model: 'url',
                        },
                    ],
                    blockSize: 12
                },
            ]
        },

        featured: {
            fields: [
                {
                    title: 'Featured',
                    component: 'ButtonFileComponent',
                    model: 'featured',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 6
                },
            ]
        },
    }
}

import {BrowserModule} from '@angular/platform-browser'
import {NgModule} from '@angular/core'

import {AppComponent} from './app.component'
import {PreloadAllModules, RouterModule} from '@angular/router'
import {AppRoutes} from './app.routes'
import {ServicesBundleModule} from './services/services-bundle.module'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {GuardBundleModule} from './guards/guard-bundle.module'
import {HTTPAuthInterceptor} from './http/auth-interceptor'
import {NotifDialogModule} from './components/notif-dialog/notif-dialog.module'
import {DashboardResolver} from './resolver/dashboard-resolver'
import {DEFAULT_TIMEOUT, defaultTimeout} from './http/http-timeout-interceptor'
import {ServiceWorkerModule} from '@angular/service-worker'
import {environment} from '../environments/environment'

@NgModule({
    imports: [
        HttpClientModule,
        BrowserModule,
        ServicesBundleModule,
        GuardBundleModule,
        NotifDialogModule,
        RouterModule.forRoot(AppRoutes, {useHash: true, preloadingStrategy: PreloadAllModules}),
        ServiceWorkerModule.register('/dashboard/ngsw-worker.js', {enabled: environment.production}),
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: HTTPAuthInterceptor,
                multi: true
            }
        ],
        [
            {
                provide: DEFAULT_TIMEOUT, useValue: defaultTimeout
            }
        ],
        DashboardResolver
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}

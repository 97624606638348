import {Type} from '@angular/core'
import {FormModelSchema} from './form-model'
import {CardTableModel} from '../../cores/creators/card-component/card-table/card-table-model'
import {InstanceViewModel} from '../../components/dynamic-layouts-view/instance-view/instance-view-model'
import {SingleImageModel} from '../../cores/creators/card-component/single-image/single-image-model'
import {ElasticModelSchema} from '../../cores/elastic/elastic-model'
import {FilterModelSchema} from '../../components/filter/filter.model'

export class CardLayoutSchema {
    editor?: CardEditorSchema
    editorPathPrefix?: string
    instanceEditor?: boolean
    exportToCsv?: boolean
    exportToCsvApiPath?: string
    model?: CardModelSchema = new CardModelSchema()
    apiModel?: CardApiSchema = {}
    elastic?: ElasticModelSchema = {}
    filter?: FilterModelSchema[]
    filterByDate?: FormModelSchema[]

}


export class CardModelSchema {
    id?: string
    /**
     * @type {{}}
     */
    title?: string

    /**
     * Accepted multiple language
     * @type {{}}
     */
    description?: string

    featured?: string

    status?: string

    /**
     * Type of card
     */
    card?: string

    email?: string

    date?: string

    position?: string

    cardClickedFn?: string

    /**
     * Options list
     * @type {any[]}
     */
    navOptions?: CardNavOptionSchema[] = []
    navFooter?: CardNavOptionSchema[] = []

    /**
     * User content if has many content
     */
    content?: {
        [key: string]: string
    }

    blockSize?: number

    cardTable?: CardTableModel
    instanceView?: InstanceViewModel
    singleImage?: SingleImageModel
}


export class CardNavOptionSchema {

    id?: string
    /**
     * Accepted multiple language
     * @type {{}}
     */
    title: string | object

    /**
     * Callback method that will be use to trigger the action
     * @Callback method from parent class/component
     */
    callbackFn: string

    icon?: string

    isDeleteAction?: boolean


}


export class CardApiSchema {
    path?: string

    paths?: string[]

    deleteModel?: string

    createModel?: string

    filterModel?: string

    params?: any

    limit?: number

    createNewFields?: FormModelSchema[] = []
    exportCsv?: ExportCsvSchema

    newModel?: string

    [key: string]: any
}


export class ExportCsvSchema {
    model?: string
    /**
     * POST or GET
     */
    type?: string
}

export class CardEditorSchema {
    pageTitle?: string
    prefixPath?: string
    component?: Type<any>
    instance?: boolean
    fields?: FormModelSchema[] | FormModelSchema
}

import {Injectable} from '@angular/core'
import {Calendar} from '../../cores/calendar/vold-calendar'
import {Observable, Subject} from 'rxjs/Rx'

@Injectable()
export class CalendarService extends Calendar {

    startDateSelected: any = null

    private _$resetCalendarDate: Subject<boolean> = new Subject<boolean>()

    constructor() {
        super()
    }

    $resetDate() {
        this._$resetCalendarDate.next(true)
    }

    $listenDateReset(): Observable<boolean> {
        return this._$resetCalendarDate.asObservable()
    }


}

import {FormModelSchema} from '../../models/dynamic-compopent/form-model'

export const FORM_NAVIGATION_EDITOR: FormModelSchema = {
    baseRoute: 'manage',
    group: {
        'sideInputs': {
            sideFields: [
                {
                    title: 'Status',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 6
                },
            ],
            fields: [
                {
                    id: 'title',
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 6
                },
                {
                    title: 'Start Data',
                    component: 'InputCalendarComponent',
                    inputCalendar: {
                        blockSize: 9,
                        dateFormat: 'dd/MM/yyyy'
                    },
                    model: 'start_date,%d',
                    blockSize: 6
                },

                {
                    title: 'End Data',
                    component: 'InputCalendarComponent',
                    inputCalendar: {
                        blockSize: 9,
                        dateFormat: 'dd/MM/yyyy'
                    },
                    model: 'end_date,%d',
                    blockSize: 6
                },

                {
                    title: 'Time',
                    component: 'InputComponent',
                    model: 'time',
                    blockSize: 6
                },

                {
                    title: 'Link',
                    component: 'InputComponent',
                    model: 'external_link',
                    blockSize: 12
                },
            ]
        },


        'media': {
            title: 'Media',
            fields: [
                {
                    title: 'Thumbnail',
                    component: 'ButtonFileComponent',
                    model: 'thumbnail',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'event'
                    },
                    blockSize: 6
                },


                {
                    title: 'Header Featured',
                    component: 'ButtonFileComponent',
                    model: 'featured',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'event'
                    },
                    blockSize: 6
                },

                {
                    title: 'Gallery',
                    component: 'ButtonFileComponent',
                    model: 'gallery',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'event',
                        multiple: true,
                    },
                    blockSize: 12
                },
            ]
        },

        'mainInputs': {
            title: 'Detail',
            fields: [
                // {
                //     id: 'author',
                //     title: 'Author',
                //     component: 'SelectOptionComponent',
                //     options: {
                //         remote: true,
                //         apiModel: {
                //             path: 'user'
                //         },
                //         value: 'name',
                //         defaultValue: 'author.name'
                //     },
                //     model: 'author',
                //     blockSize: 6
                // },

                {
                    title: 'Project Type',
                    component: 'SelectOptionComponent',
                    options: {
                        remote: true,
                        apiModel: {
                            path: 'projecttype',
                            params : 'sort=title ASC'
                        },
                        value: 'title:lang',
                        defaultValue: 'project_type.title:lang'
                    },
                    model: 'project_type',
                    blockSize: 6
                },

                {
                    title: 'Description',
                    component: 'TextEditorComponent',
                    blockSize: 12,
                    model: 'description:lang'
                },
            ],
        },

        'locations': {
            fields: [

                {
                    id: 'title',
                    title: 'Address',
                    component: 'InputComponent',
                    model: 'address',
                    blockSize: 12
                },

                {
                    title: 'Location',
                    component: 'GoogleMapComponent',
                    model: 'latitude_longitude',
                    blockSize: 12
                },
            ]
        }
    }
}

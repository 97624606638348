import {Injectable} from '@angular/core'
import {Subject} from 'rxjs/Subject'

@Injectable()
export class CreateNewPostService {

    private _$listenNewPost: Subject<any> = new Subject<any>()


    $setNewPost(val: any) {
        this._$listenNewPost.next(val)
    }

    $getNewPost() {
        return this._$listenNewPost.asObservable()
    }

}

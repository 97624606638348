import {Injectable} from '@angular/core'
import {NotifDialogModel} from '../models/notif/notif-dialog-model'
import {Subject} from 'rxjs/Subject'

@Injectable()
export class NotifDialogService {

    private _$listenDialog: Subject<any> = new Subject<any>()

    showDialog(options: NotifDialogModel) {
        /**
         * Gusna taen mai
         */
        options.timeOutClose = 2000
        this.$setDialog(options)
    }

    $setDialog(options: NotifDialogModel) {
        this._$listenDialog.next(options)
    }

    $watchDialog() {
        return this._$listenDialog.asObservable()
    }

}

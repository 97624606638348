import {FormModelSchema} from '../../models/dynamic-compopent/form-model'

export const FORM_STORY_BOGA: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'story',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Active',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 12,
                },

                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 12
                },

                {
                    title: 'Year',
                    component: 'InputComponent',
                    model: 'year',
                    type: 'number',
                    blockSize: 12
                },

                {
                    title: 'Milestone Title',
                    component: 'InputComponent',
                    model: 'items.milestone_title:lang',
                    blockSize: 12
                },

                {
                    title: 'Milestone Body',
                    component: 'TextEditorComponent',
                    model: 'items.milestone_body:lang',
                    blockSize: 12
                },

                {
                    title: 'Achievements Title',
                    component: 'InputComponent',
                    model: 'items.achievements_title:lang',
                    blockSize: 12
                },
            

                {
                    title: 'Achievements Body',
                    component: 'TextEditorComponent',
                    model: 'items.achievements_body:lang',
                    blockSize: 12
                },
            ]
        },

        projectCost: {
            fields: [
                {
                    title: 'Featured',
                    component: 'ButtonFileComponent',
                    model: 'featured',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 6
                },
            ]
        },
    }
}

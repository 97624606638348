import {FormModelSchema} from '../../models/dynamic-compopent/form-model'

export const FORM_BRANDS_EDITOR: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'bogabrand',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Status',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 6,
                },

                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title',
                    blockSize: 12
                },

                {
                    title: 'Website Url',
                    component: 'InputComponent',
                    model: 'website_url',
                    blockSize: 12
                },
            ]
        },

        sections: {
            fields: [
                {
                    component: 'MultipleAddComponent',
                    model: 'sections',
                    title: 'Sections',
                    blockSize: 12,
                    multipleAdd: {
                        blockSize: 6,
                        buttonTitle: 'title:lang'
                    },
                    multipleComponentToUse: [
                        {
                            title: 'Title',
                            component: 'InputComponent',
                            model: 'title:lang',
                            blockSize: 6,
                        },

                        {
                            title: 'Description',
                            component: 'InputComponent',
                            model: 'description:lang',
                            blockSize: 6,
                        },

                        {
                            title: 'Section Banner',
                            component: 'ButtonFileComponent',
                            model: 'featured',
                            buttonFile: {
                                buttonType: 'image',
                                folderTarget: 'post',
                            },
                            blockSize: 6,
                        },
                    ]
                },
            ]
        },

        // Locations [array]
        locations: {
            fields: [
                {
                    component: 'MultipleAddComponent',
                    model: 'locations',
                    title: 'Locations',
                    blockSize: 12,
                    multipleAdd: {
                        blockSize: 6,
                        buttonTitle: 'building'
                    },
                    multipleComponentToUse: [
                        {
                            title: 'Region',
                            component: 'InputComponent',
                            model: 'city',
                            blockSize: 12,
                        },

                        {
                            title: 'Building',
                            component: 'InputComponent',
                            model: 'building',
                            blockSize: 6,
                        },

                        {
                            title: 'Address',
                            component: 'InputComponent',
                            model: 'address',
                            blockSize: 6,
                        },

                        {
                            title: 'Zip Code',
                            component: 'InputComponent',
                            type: 'number',
                            model: 'zipcode',
                            blockSize: 6,
                        },

                        {
                            title: 'Phone',
                            component: 'InputComponent',
                            model: 'phone',
                            blockSize: 6,
                        },
                    ]
                },
            ]
        },

        gallery: {
            fields: [
                {
                    title: 'Header image',
                    component: 'ButtonFileComponent',
                    model: 'featured',
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    },
                    blockSize: 12
                },

                {
                    title: 'Logo Color',
                    component: 'ButtonFileComponent',
                    model: 'logo',
                    blockSize: 6,
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    }
                },

                {
                    title: 'Logo White',
                    component: 'ButtonFileComponent',
                    model: 'logo_white',
                    blockSize: 6,
                    buttonFile: {
                        buttonType: 'image',
                        folderTarget: 'post',
                    }
                },

                {
                    title: 'Gallery',
                    component: 'ButtonFileComponent',
                    model: 'gallery',
                    blockSize: 12,
                    buttonFile: {
                        icon: 'icon-plus',
                        buttonType: 'image',
                        folderTarget: 'post',
                        multiple: true
                    }
                },
            ]
        },
    }
}

import {Injectable} from '@angular/core'
import {Subject} from 'rxjs/Rx'

@Injectable()
export class InfiniteScrollService {

    private _$hostDataHasChanged: Subject<boolean> = new Subject<boolean>()

    $hostDataUpdated(val) {
        this._$hostDataHasChanged.next(val)
    }

    $listenHostUpdateData() {
        return this._$hostDataHasChanged.asObservable()
    }


}

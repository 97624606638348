import { FormModelSchema } from '../../models/dynamic-compopent/form-model'

export const FORM_JCO_FLASH: FormModelSchema = {
    baseRoute: 'manage',
    pathModel: 'event',
    group: {
        'projectDetail': {
            fields: [
                {
                    title: 'Publish',
                    component: 'SlideToggleComponent',
                    slideToggle: {
                        values: ['published', 'draft']
                    },
                    model: 'status',
                    blockSize: 12,
                },

                {
                    title: 'Title',
                    component: 'InputComponent',
                    model: 'title:lang',
                    blockSize: 12
                },
                {
                    title: 'Body',
                    component: 'TextareaComponent',
                    model: 'body:lang',
                    blockSize: 12
                },
                {
                    title: 'LINK',
                    component: 'InputComponent',
                    model: 'meta.link',
                    blockSize: 12
                },

                // {
                //     title: 'Expired At',
                //     component: 'InputCalendarComponent',
                //     inputCalendar: {
                //         blockSize: 9,
                //         dateFormat: 'dd/MM/yyyy'
                //     },
                //     model: 'datetime,%d',
                //     blockSize: 6
                // },

            ]
        },
    }
}

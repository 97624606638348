// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export let environment = {
    production: false,

    web_uri: '',
    api_uri: 'https://api.vold.io/',
    // api_uri: 'https://api.beta.vold.dev.fleava.com/',
    // api_uri: 'http://localhost:1338/',

    assets_uri: 'https://api.vold.io',
    // assets_uri: 'https://api.beta.vold.dev.fleava.com/',
    // assets_uri: 'http://localhost:1338/',

    front_web_uri: '',
    // access_origin: 'http://localhost:4200',
    /**
     * Node origin
     */
    // access_origin: 'http://localhost:7000',
    access_origin: 'https://app.vold.io'
}
